import { useApiLoading, useApiGet } from "react-reqq";

import * as c from "./constants";

export const useServiceTypeList = () => {
  const isLoading = useApiLoading(c.GET_SERVICES, "get");
  const list = useApiGet(c.GET_SERVICES, []);

  return [list, isLoading];
};

export const useGuestAmounList = () => {
  const isLoadingAmounts = useApiLoading(c.RECHARGE_PRE_AMOUNTS, "get");
  const options = useApiGet(c.RECHARGE_PRE_AMOUNTS, []);

  return [options, isLoadingAmounts];
};

export const useSubmitIsLoading = () => {
  const prepaidIsLoading = useApiLoading(c.RECHARGE_ACCOUNT, "post");
  const postpaidIsLoading = useApiLoading(c.PAY_POSTPAID, "post");
  const voucherIsLoading = useApiLoading(c.PAY_VOUCHER, "post");
  const isLoading = prepaidIsLoading || postpaidIsLoading || voucherIsLoading;

  return isLoading;
};

export const useGuestIsLoading = () => {
  const rechargeCardIsLoading = useApiLoading(c.RECHARGE_ACCOUNT, "post");
  const rechargeVoucherIsLoading = useApiLoading(c.PAY_VOUCHER, "post");
  const postpaidIsLoading = useApiLoading(c.PAY_POSTPAID, "post");
  const fixedlineIsLoading = useApiLoading(c.PAY_FIXEDLINE, "post");

  return (
    rechargeCardIsLoading ||
    rechargeVoucherIsLoading ||
    postpaidIsLoading ||
    fixedlineIsLoading
  );
};
