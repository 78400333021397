import React from "react";

import packageJson from "../../../../package.json";
import { getBuildDate } from "modules/common/helper";

function AppFooter() {
  return (
    <div className="flex flex-col items-center">
      <span>Version 1.5.0 ©2021 DST</span>
      <span className="text-sm">
        Build date: {getBuildDate(packageJson.buildDate)}
      </span>
    </div>
  );
}

export default AppFooter;
