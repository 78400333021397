import { req } from "react-reqq";

import * as c from "./constants";
import * as t from "./transformers";

export const listSubscriptionTypes = () => {
  req.get({
    key: c.REGISTER_SUBSCRIPTION_LIST,
    url: "/api/v2/public/subscription/select",
    transform: t.transformSubscriptionTypeList,
  });
};

export const listServiceTypes = (subscription_code) => {
  req.get({
    key: c.REGISTER_SERVICE_LIST(subscription_code),
    url: `/api/v2/public/subscription/${subscription_code}/service/select`,
    transform: t.transformServiceTypeList,
  });
};

export const listSecurityQuestions = () => {
  req.get({
    key: c.LIST_SEC_QUESTIONS,
    url: "/api/v1/security_questions",
  });
};

export const accountLinking = (payload, callback) => {
  req.post({
    key: c.ACCOUNT_LINKING,
    url: "/api/v2/user/subscriber/account_link",
    payload,
    onSuccess: ({ response }) => {
      if (callback) callback(response);
    },
  });
};

export const accountVerification = (payload, setError, callback) => {
  req.post({
    key: c.ACCOUNT_VERIFICATION,
    url: "/api/v2/user/subscriber/account_link/confirm",
    payload,
    onSuccess: () => {
      callback();
    },
    onError: (err) => {
      if (err.status === 400) {
        setError((prev) => ({ ...prev, otp: { message: "Invalid OTP" } }));
      }
    },
  });
};

export const accountDetailsRegistration = (payload, callback) => {
  req.post({
    key: c.ACCOUNT_REGISTRATION,
    url: "/api/v2/user/subscriber/register/account_details",
    payload,
    onSuccess: () => {
      callback();
    },
  });
};

export const accountPersonalInfoRegistration = (payload, callback) => {
  req.post({
    key: c.ACCOUNT_REGISTRATION,
    url: "/api/v2/user/subscriber/register/personal_info",
    payload,
    onSuccess: () => {
      callback();
    },
  });
};

export const accountSecurityQuestionRegistration = (payload, callback) => {
  req.post({
    key: c.ACCOUNT_REGISTRATION,
    url: "/api/v2/user/subscriber/register/security_question",
    payload,
    onSuccess: () => {
      callback();
    },
  });
};
