import { useCallback, useEffect } from "react";
import { useApiGet, useApiLoading } from "react-reqq";

import * as actions from "./actions";
import * as c from "./constants";

export const useSubscriptionTypeList = () => {
  const data = useApiGet(c.REGISTER_SUBSCRIPTION_LIST, []);
  const isLoading = useApiLoading(c.REGISTER_SUBSCRIPTION_LIST, "get");

  const fetchData = useCallback(() => {
    actions.listSubscriptionTypes();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return [data, isLoading, fetchData];
};

export const useServiceTypeList = (subscription_code) => {
  const data = useApiGet(c.REGISTER_SERVICE_LIST(subscription_code), []);
  const isLoading = useApiLoading(
    c.REGISTER_SERVICE_LIST(subscription_code),
    "get"
  );

  const fetchData = useCallback(() => {
    actions.listServiceTypes(subscription_code);
  }, [subscription_code]);

  useEffect(() => {
    if (subscription_code) {
      fetchData();
    }
  }, [subscription_code]);

  return [data, isLoading, fetchData];
};
