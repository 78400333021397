import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import IconEmail from "assets/img/icon-email.svg";

function RegistrationSuccess({ form, onClose }) {
  return (
    <div className="h-full flex flex-col items-center p-8">
      <img alt="" src={IconEmail} />
      <h1 className="text-3xl font-semibold">Email Sent</h1>
      <p className="text-sm text-center text-gray-700 mt-4">
        We sent a temporary email password to your email <br />{" "}
        <span className="font-semibold">{`${get(
          form,
          "email_address",
          "--"
        )}`}</span>
      </p>
      <p className="text-sm text-center text-gray-700 mt-4">
        Please log in to MyDST Mobile or Web App using your <br />
        temporary password.
      </p>
      <button
        type="button"
        className="btn primary w-full md:w-64 mx-auto mt-auto"
        onClick={onClose}
      >
        Continue to Login
      </button>
    </div>
  );
}

RegistrationSuccess.propTypes = {
  form: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RegistrationSuccess;
