import React from "react";
import PropTypes from "prop-types";

function FormRadioButton({
  name,
  value,
  label,
  disabled,
  checked,
  validation,
  leftIcon,
  ...rest
}) {
  return (
    <label
      className={`flex cursor-pointer text-sm items-center bg-white shadow hover:shadow-md px-6 rounded-lg py-2 border`}
      htmlFor={value}
    >
      {leftIcon && <span className="mr-4">{leftIcon}</span>}
      <span className="font-medium text-gray-700 text-xs">{label}</span>
      <input
        id={value}
        type="radio"
        value={value}
        name={name}
        className="mb-0 ml-auto"
        ref={validation({ required: false })}
        {...rest}
      />
    </label>
  );
}

FormRadioButton.defaultProps = {
  label: "",
  value: null,
  disabled: false,
  checked: "",
  onChange: () => {},
  leftIcon: "",
};

FormRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(),
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  leftIcon: PropTypes.element,
};

export default FormRadioButton;
