import React, { useEffect, useRef, useState } from "react";

// import BannerFirst from "assets/img/banner-first.svg";
// import BannerSecond from "assets/img/banner-second.svg";
// import BannerThird from "assets/img/banner-third.svg";

const slidersData = [
  {
    title: "Refreshed!",
    description: "New experience with you in mind.",
  },
  {
    title: "All at your fingertips",
    description:
      "Manage your usage, pay your bills, buy add-ons and make donations!",
  },
  {
    title: "Pay for family & friends.",
    description: "Pay as guest now available for all of our products.",
  },
  {
    title: "Fast and secure",
    description:
      "With added new security features like mobile PIN, Face ID, & fingerprint.",
  },
  {
    title: "Refreshed!",
    description: "New experience with you in mind.",
  },
];

function LoginSliders() {
  const [slide, setSlide] = useState(0);
  const [showTransition, setShowTransition] = useState(true);
  const timeoutRef = useRef();

  const handleNextPrev = (action) => () => {
    switch (action) {
      case "prev":
        setSlide((prev) => Math.max(prev - 1, 0));
        break;

      default:
        setSlide((prev) => Math.min(prev + 1, slidersData.length - 1));
        break;
    }
  };

  useEffect(() => {
    if (!showTransition) setSlide(0);
  }, [showTransition]);

  useEffect(() => {
    if (slide === 0) setTimeout(() => setShowTransition(true), 10);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      handleNextPrev("next")();
    }, [5000]);
    return () => clearTimeout(timeoutRef.current);
  }, [slide]);

  return (
    <div className="h-full flex flex-col items-center">
      <div className="w-96 overflow-hidden flex relative">
        <div className="absolute w-full h-full flex z-10">
          <button className="h-full w-1/2" onClick={handleNextPrev("prev")} />
          <button className="h-full w-1/2" onClick={handleNextPrev("next")} />
        </div>
        <div
          className={`flex ${
            showTransition ? "transition-transform duration-300 ease-in" : ""
          }`}
          style={{ transform: `translateX(-${slide * 24}rem)` }}
          onTransitionEnd={() =>
            slide === slidersData.length - 1 && setShowTransition(false)
          }
        >
          {slidersData.map((slideData, i) => (
            <div
              key={`${slideData.title}-${i}`}
              className="w-96 flex flex-shrink-0 flex-col items-center"
            >
              <div className="my-3 text-xl text-center text-white">
                <p className="text-4xl font-medium tracking-wide">
                  {slideData.title}
                </p>
                <p className="font-light tracking-wide mt-3">
                  {slideData.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-auto flex gap-2">
        {[...slidersData].slice(0, -1).map((slideData, i) => (
          <button key={slideData.title} onClick={() => setSlide(i)}>
            <i
              className={`fa fa-circle ${
                slide === i ? "text-primary-800" : "text-primary-300"
              }`}
            />
          </button>
        ))}
      </div>
    </div>
  );
}

export default LoginSliders;
