import React from "react";
import PropTypes from "prop-types";

import IconLock from "assets/img/icon-lock.svg";

function ChangePasswordSucess({ onClose }) {
  return (
    <div className="h-full flex flex-col items-center p-8">
      <img alt="" src={IconLock} />
      <h1 className="text-3xl font-medium text-center">
        Password Successfully Changed
      </h1>
      <button
        type="button"
        className="btn primary w-full md:w-64 mt-6 md:mt-auto mx-auto"
        onClick={onClose}
      >
        Continue
      </button>
    </div>
  );
}

ChangePasswordSucess.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChangePasswordSucess;
