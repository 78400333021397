import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import PublicBanners from "modules/partial/containers/PublicBanners";
import withClearCache from "./ClearCache";

import Login from "modules/auth/containers/Login";
import PayAsGuestContainer from "modules/guest/containers/PayAsGuestContainer";
import RegistrationContainer from "modules/registration/containers/RegistrationContainer";
import ForgotPasswordContainer from "modules/forgot-credentials/containers/ForgotPasswordContainer";
import ForgotMpinContainer from "modules/forgot-credentials/containers/ForgotMpinContainer";
import SecurityContainer from "modules/security/containers/SecurityContainer";
import PaymentRedirect from "modules/payment/containers/PaymentRedirect";
import ProductDescription from "modules/web-view/containers/ProductDescription";

function WebAppPublicRoutes() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/registration" component={RegistrationContainer} />
      <Route path="/pay-as-guest" component={PayAsGuestContainer} />
      <Route path="/forgot-password" component={ForgotPasswordContainer} />
      <Route path="/forgot-pin" component={ForgotMpinContainer} />
      <Route path="/security" component={SecurityContainer} />
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  );
}

const ClearCacheComponent = withClearCache(WebAppPublicRoutes);

function Public() {
  return (
    <div className="bg-public min-h-screen min-w-screen flex w-full h-full">
      <div className="public-form">
        <Switch>
          <Route path="/payment" component={PaymentRedirect} />
          <Route
            path="/web_view/product_description"
            component={ProductDescription}
          />
          <Route component={ClearCacheComponent} />
        </Switch>
      </div>
      <div className="public-banner">
        <PublicBanners />
      </div>
    </div>
  );
}

Public.propTypes = {};

export default Public;
