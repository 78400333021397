// for postpaid //
export const GET_POSTPAID_INVOICE = "PAYBILL/GET_POSTPAID_INVOICE";
export const GET_POSTPAID_INVOICE_GRAPH = "PAYBILL/GET_INVOICE_POSTPAID_GRAPH";
export const SELECTED_POSTPAID_INVOICE = "PAYBILL/SELECTED_POSTPAID_INVOICE";
export const DOWNLOAD_POSTPAID_INVOICE = "PAYBILL/download_postpaid_invoice";

// Pay Bill //
export const PAY_SUBSCRIPTION_BILL = "PAYBILL/pay_subscription_bill";
export const PAY_SUBSCRIPTION_BILL_CALLBACK = "PAYBILL/pay_subs_bill_callback";
export const POSTPAID_PAYMENT_HISTORY = "PAYBILL/postpaid_payment_history";

// for fixed line //
export const GET_FIXEDLINE_INVOICE = "PAYBILL/GET_FIXED_LINE_INVOICE";
export const GET_FIXEDLINE_INVOICE_GRAPH =
  "PAYBILL/GET_FIXED_LINE_INVOICE_GRAPH";
export const SELECTED_FIXEDLINE_INVOICE =
  "PAYBILL/GET_SELECTED_FIXEDLINE_INVOICE";
export const DOWNLOAD_FIXEDLINE_INVOICE = "PAYBILL/download_fixedline_invoice";

export const GET_FIXEDLINE_PAYMENT_HISTORY =
  "PAYBILL/get_fixedline_payment_history";
export const PAY_FIXEDLINE_BILL = "PAYBILL/pay_fixedline_bill";
export const PAY_FIXEDLINE_CALLBACK = "PAYBILL/pay_fixedline_callback";
// export const DOWNLOAD_FIXEDLINE_BILL = "PAYBILL/download_fixedline_bill";
