import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormPinInput from "modules/common/forms/FormPinInput";

function SetPinConfirmation({ initValues, onSubmit }) {
  const history = useHistory();
  const validationSchema = yup.object({
    confirmation_mpin: yup
      .string()
      .length(6)
      .oneOf([initValues.mpin, null], "PIN doesn't match"),
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initValues,
  });

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Confirm Security PIN</h1>
      <p className="text-gray-600 text-sm mt-4">
        Make sure to enter the same 6 digit PIN
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormPinInput
          name="confirmation_mpin"
          validation={register}
          error={errors.confirmation_mpin}
          numbersOnly
          mask
        />
        <p className="text-gray-600 text-xs tracking-wide mt-2">
          Go back to{" "}
          <button
            onClick={() => {
              history.push("/security/set-pin");
            }}
          >
            <span className="text-primary-800">Change PIN</span>
          </button>
        </p>

        <button className="btn primary w-full mx-auto mt-auto" type="submit">
          Confirm PIN
        </button>
      </form>
    </div>
  );
}

SetPinConfirmation.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
};

export default SetPinConfirmation;
