import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { req } from "react-reqq";
import { toast } from "react-toastify";
import Cookie from "js-cookie";

import { showModal } from "modules/common/components/Modal";
import { useAccountAttributes } from "modules/account/hooks";
import { ACCOUNT_INFO } from "modules/account/constants";
import { AUTHENTICATION } from "modules/auth/constants";

import ChangeTempPassword from "../components/ChangeTempPassword";
import SetSecurityPin from "../components/SetSecurityPin";
import SetPinConfirmation from "../components/SetPinConfirmation";
import * as actions from "../actions";
import ChangePasswordSucess from "../modals/ChangePasswordSucess";

function SecurityContainer() {
  const history = useHistory();
  const location = useLocation();
  const [accountAttributes] = useAccountAttributes();
  const [form, setForm] = useState({
    // old_password: temp_pass,
  });
  const [currStep, setCurrStep] = useState(0);
  const baseUrl = "security";
  const routes = [];

  if (accountAttributes.is_temp_password) {
    routes.push("change-temp-password");
  }
  if (!accountAttributes.is_set_mpin) {
    routes.push("set-pin");
    routes.push("confirm-pin");
  }

  const handlePrevStep = () => {
    if (!currStep) return;
    history.push(`/${baseUrl}/${routes[currStep - 1]}`);
  };

  const handleNextStep = () => {
    if (routes.length === currStep + 1) return;
    history.push(`/${baseUrl}/${routes[currStep + 1]}`);
  };

  const handleShowSucessModal = () => {
    showModal({
      align: "items-center justify-center bg-primary-800 bg-opacity-75",
      className: "w-full md:w-100 md:h-84",
      content: (close) => (
        <ChangePasswordSucess
          onClose={() => {
            close();
            if (accountAttributes.is_set_mpin) {
              req.set(ACCOUNT_INFO, {});
              req.set(AUTHENTICATION, {
                isPasswordAuthenticated: true,
                isPinAuthenticated: true,
              });
              return;
            }
            handleNextStep();
          }}
        />
      ),
    });
  };

  const handleStepsSubmits = (code) => (data) => {
    setForm((prev) => ({ ...prev, ...data }));
    const { mpin = "" } = form;
    switch (code) {
      case "pw":
        actions.setNewPassword(data, handleShowSucessModal);
        break;

      case "pin":
        handleNextStep();
        break;

      case "pin2":
        actions.setPin({ mpin, ...data }, () => {
          toast("Successfully set PIN");
          req.set(ACCOUNT_INFO, {});
          req.set(AUTHENTICATION, {
            isPasswordAuthenticated: true,
            isPinAuthenticated: true,
          });
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const token = Cookie.get("_token");
    if (!token) {
      return history.push("/login");
    }
    history.push(`/${baseUrl}/${routes[currStep]}`);
  }, []);

  useEffect(() => {
    setCurrStep(routes.indexOf(location.pathname.replace(`/${baseUrl}/`, "")));
  }, [location.pathname]);

  return (
    <div className="w-full flex flex-col h-full pt-10 pb-8 md:px-12 relative">
      <div>
        <button
          className="shadow-xs px-2 py-1 rounded bg-white text-normal"
          onClick={handlePrevStep}
        >
          <i className="fa fa-arrow-left" /> Back
        </button>
      </div>
      <div className="mt-8 flex">
        {routes.map((link, i) => (
          <div
            key={link}
            className={`h-2 w-10 rounded-full ${
              currStep >= i ? "bg-secondary-800" : "bg-gray-500"
            } ${i > 0 ? "ml-2" : ""}`}
          />
        ))}
      </div>
      <Switch>
        {routes.indexOf("change-temp-password") !== -1 && (
          <Route
            path={`/${baseUrl}/change-temp-password`}
            render={() => (
              <ChangeTempPassword
                initValues={form}
                onSubmit={handleStepsSubmits("pw")}
              />
            )}
          />
        )}
        {routes.indexOf("set-pin") !== -1 && (
          <>
            <Route
              path={`/${baseUrl}/set-pin`}
              render={() => (
                <SetSecurityPin
                  initValues={form}
                  onSubmit={handleStepsSubmits("pin")}
                />
              )}
            />
            <Route
              path={`/${baseUrl}/confirm-pin`}
              render={() => (
                <SetPinConfirmation
                  initValues={form}
                  onSubmit={handleStepsSubmits("pin2")}
                />
              )}
            />
          </>
        )}
        <Route
          render={() => <Redirect to={`/${baseUrl}/${routes[currStep]}`} />}
        />
      </Switch>
    </div>
  );
}

export default SecurityContainer;
