import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useApiLoading } from "react-reqq";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormPassword from "modules/common/forms/FormPassword";

import * as c from "../../registration/constants";

const validationSchema = yup.object({
  old_password: yup.string().required("Temporary password is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "At least 8 characters")
    .matches(/[A-Z]/, "Must contain 1 capital letter")
    .matches(/[0-9]/, "Must contain 1 numeric digit"),
  // .matches(/^[a-zA-Z0-9]{1,}$/, "Special character is not allowed"),
  confirmation_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
});

function ChangeTempPassword({ initValues, onSubmit }) {
  const isLoading = useApiLoading(c.ACCOUNT_REGISTRATION, "post");
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const passwordField = watch().password;
  const confirmPasswordField = watch().confirmation_password;
  const conditionOne = passwordField?.length >= 8;
  const conditionTwo = /[A-Z]/.test(passwordField);
  const conditionThree = /[0-9]/.test(passwordField);
  // const conditionFour = /^[a-zA-Z0-9]{1,}$/.test(passwordField);
  const conditionFive = useMemo(
    () => passwordField === confirmPasswordField && passwordField !== "",
    [passwordField, confirmPasswordField]
  );

  const conditionClass = useCallback(
    (isCheck) => {
      return `text-sm ${isCheck ? "text-secondary-800" : "text-red-500"}`;
    },
    [conditionOne, conditionTwo, conditionThree]
  );

  const renderChecklistIcon = useCallback(
    (isCheck) => (
      <i
        className={`text-sm mr-1 ${
          isCheck
            ? "text-secondary-800 fa fa-check-circle"
            : "text-red-500 fa fa-times-circle"
        }`}
      />
    ),
    [conditionOne, conditionTwo, conditionThree, conditionFive]
  );

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Set a new Password</h1>
      <p className="text-gray-600 text-sm mt-4">
        You will use this to login next time.
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormPassword
          name="old_password"
          label="Temporary Password"
          validation={register}
          error={errors.old_password}
        />

        <FormPassword
          name="password"
          label="New Password"
          validation={register}
        />

        <FormPassword
          name="confirmation_password"
          label="Confirm Password"
          validation={register}
        />

        <ul className="mt-4">
          <li className={conditionClass(conditionOne)}>
            {renderChecklistIcon(conditionOne)}
            At least 8 characters
          </li>
          <li className={conditionClass(conditionTwo)}>
            {renderChecklistIcon(conditionTwo)}
            Must contain 1 capital letter
          </li>
          <li className={conditionClass(conditionThree)}>
            {renderChecklistIcon(conditionThree)}
            Must contain 1 numeric digit
          </li>
          {/* <li className={conditionClass(conditionFour)}>
            {renderChecklistIcon(conditionFour)}
            Special character is not allowed
          </li> */}
          <li className={conditionClass(conditionFive)}>
            {renderChecklistIcon(conditionFive)}
            Password must match
          </li>
        </ul>

        <button
          disabled={isLoading}
          className="btn primary w-full mx-auto mt-auto"
          type="submit"
        >
          Next
        </button>
      </form>
    </div>
  );
}

ChangeTempPassword.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeTempPassword;
