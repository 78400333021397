import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useApiLoading } from "react-reqq";
import { get } from "lodash";

import FormInput from "modules/common/forms/FormInput";

import * as c from "../constants";

function SetAccountDetails({ initValues, onSubmit }) {
  const history = useHistory();
  const validationSchema = yup.object({
    email_address: yup
      .string()
      .required("Email is required")
      .email("Must be a valid email format"),
    username: yup.string().required("User ID is required"),
  });
  const isLoading = useApiLoading(c.ACCOUNT_REGISTRATION, "post");
  const [formErrors, setFormErrors] = useState({});
  const { register, handleSubmit, errors } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setFormErrors((prevErrors) => ({ ...prevErrors, ...errors }));
  }, [errors]);

  useEffect(() => {
    if (!get(initValues, "service_number", ""))
      history.push("/registration/step1");
  }, []);

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Your Account Details</h1>
      <p className="text-gray-600 text-sm mt-4">
        Fill out the basic information below.
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInput
          placeholder="name@email.com"
          name="email_address"
          label="Email Address"
          validation={register}
          containerClassName="mb-2"
          error={formErrors.email_address}
        />

        <FormInput
          placeholder="userid"
          name="username"
          label="User ID"
          validation={register}
          containerClassName="mb-2"
          error={formErrors.username}
        />

        <button
          disabled={isLoading}
          className="btn primary w-full mx-auto mt-auto"
          type="submit"
        >
          Next
        </button>
      </form>
    </div>
  );
}

SetAccountDetails.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
};

export default SetAccountDetails;
