import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import marked from "marked";

function MarkdownRenderer({ url }) {
  const [rawMarkUp, setMarkUp] = useState("");
  const getMdFile = () => {
    fetch(url)
      .then((res) => res.text())
      .then((text) => {
        setMarkUp(marked(text));
      });
  };

  useEffect(() => {
    if (url) {
      getMdFile();
    }
  }, [url]);

  return <div dangerouslySetInnerHTML={{ __html: rawMarkUp }} />;
}

MarkdownRenderer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default MarkdownRenderer;
