import { req } from "react-reqq";
import { get } from "lodash";

import { appendObjectParams } from "modules/common/helper";

import * as c from "./constants";

export const listPredefinedAmounts = () => {
  req.get({
    key: c.RECHARGE_PRE_AMOUNTS,
    url: "/api/v1/amount_recharges",
    transform: (res) => {
      return get(res, "data", []).map(
        ({ attributes: { amount, refill_profile_id } }) => ({
          amount,
          refill_profile_id,
        })
      );
    },
  });
};

export const rechargeAccount = (service_number, payload, callback) => {
  const params = {
    is_webapp: 1,
    platform: "WEB",
  };
  req.post({
    key: c.RECHARGE_ACCOUNT,
    url: `/api/v1/prepaids/${service_number}/recharges${appendObjectParams(
      params
    )}`,
    payload,
    onSuccess: ({ response }) => {
      if (
        payload?.payment_method === "voucher" &&
        typeof callback === "function"
      ) {
        callback();
        return;
      }
      window.location.href = get(response, "data.attributes.url", "");
    },
  });
};

export const rechargeCallback = (payload, onSuccess, onError) => {
  req.post({
    key: c.RECHARGE_ACCOUNT_CALLBACK,
    url: `/api/v1/callbacks/prepaids/recharges`,
    payload,
    onSuccess: ({ response: { data } }) => {
      if (data?.attributes?.url) {
        return (window.location.href = get(data, "attributes.url", ""));
      }
      if (typeof onSuccess === "function") onSuccess(data);
    },
    onError: () => {
      if (typeof onError === "function") onError();
    },
  });
};
