import React, { useState } from "react";
import { Route, Switch, useParams, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { showModal } from "modules/common/components/Modal";

import RegistrationSuccess from "../modals/RegistrationSuccess";
import SetServiceInfo from "../components/SetServiceInfo";
import VerifyAccount from "../components/VerifyAccount";
import SetAccountDetails from "../components/SetAccountDetails";
import SetAccountDetailsTwo from "../components/SetAccountDetailsTwo";
import SetSecurityQuestion from "../components/SetSecurityQuestion";
import * as actions from "../actions";

function RegistrationSteps() {
  const history = useHistory();
  const { step } = useParams();
  const [form, setForm] = useState({});
  const [emailToSendOtp, setEmailToSendOtp] = useState("");
  const [responseErrors, setResponseErrors] = useState({});
  const baseUrl = "registration";

  const handlePrevStep = () => {
    const prevStep = parseInt(step.replace("step", ""), 10) - 1;
    if (prevStep === 0) {
      history.push("/");
      return;
    }
    history.push(`/${baseUrl}/step${prevStep}`);
  };

  const handleNextStep = () => {
    const nextStep = parseInt(step.replace("step", ""), 10) + 1;
    if (nextStep === 6) {
      return;
    }
    history.push(`/${baseUrl}/step${nextStep}`);
  };

  const handleShowModal = () => {
    showModal({
      align: "items-center justify-center bg-primary-800 bg-opacity-75",
      className: "w-full h-full md:w-auto md:h-100",
      content: (close) => (
        <RegistrationSuccess
          form={form}
          onClose={() => {
            close();
            history.push("/login");
          }}
        />
      ),
    });
  };

  const handleStepsSubmits = (stepNum) => (data) => {
    const {
      subscription_type = "",
      service_type = "",
      service_type_id = "",
      service_number = "",
      email_address = "",
      username = "",
    } = form;
    if (stepNum !== 2) setForm((prev) => ({ ...prev, ...data }));
    switch (stepNum) {
      case 1:
        actions.accountLinking(data, (response) => {
          setEmailToSendOtp(response?.email_address);
          handleNextStep();
        });
        break;

      case 2:
        actions.accountVerification(
          { ...form, ...data },
          setResponseErrors,
          handleNextStep
        );
        break;

      case 3:
        actions.accountDetailsRegistration(
          {
            ...form,
            ...data,
          },
          handleNextStep
        );
        break;

      case 4:
        actions.accountPersonalInfoRegistration(
          {
            ...form,
            ...data,
          },
          handleNextStep
        );
        break;

      case 5:
        actions.accountSecurityQuestionRegistration(
          {
            subscription_type,
            service_type,
            service_type_id,
            service_number,
            email_address,
            username,
            ...data,
          },
          handleShowModal
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className="w-full flex flex-col h-full pt-10 pb-8  relative">
      <div className="fixed z-50 top-0 left-0 bg-white w-full h-full flex flex-col p-4 md:bg-transparent md:static">
        <div>
          <button
            className="shadow-xs px-2 py-1 rounded bg-white text-normal"
            onClick={handlePrevStep}
          >
            <i className="fa fa-arrow-left" /> Back
          </button>
        </div>
        <div className="mt-8 flex md:ml-4">
          {[1, 2, 3, 4, 5].map((i) => (
            <div
              key={i}
              className={`h-2 w-10 rounded-full ${
                parseInt(step.replace("step", ""), 10) >= i
                  ? "bg-secondary-800"
                  : "bg-gray-300"
              } ${i > 1 ? "ml-2" : ""}`}
            />
          ))}
        </div>
        <div className="mt-4 h-full flex flex-col md:px-4">
          <Switch>
            <Route
              path={`/${baseUrl}/step1`}
              render={() => (
                <SetServiceInfo
                  initValues={form}
                  onSubmit={handleStepsSubmits(1)}
                />
              )}
            />
            <Route
              path={`/${baseUrl}/step2`}
              render={() => (
                <VerifyAccount
                  initValues={form}
                  email={emailToSendOtp}
                  onSubmit={handleStepsSubmits(2)}
                  responseErrors={responseErrors}
                />
              )}
            />
            <Route
              path={`/${baseUrl}/step3`}
              render={() => (
                <SetAccountDetails
                  initValues={form}
                  onSubmit={handleStepsSubmits(3)}
                />
              )}
            />
            <Route
              path={`/${baseUrl}/step4`}
              render={() => (
                <SetAccountDetailsTwo
                  initValues={form}
                  onSubmit={handleStepsSubmits(4)}
                />
              )}
            />
            <Route
              path={`/${baseUrl}/step5`}
              render={() => (
                <SetSecurityQuestion
                  initValues={form}
                  onSubmit={handleStepsSubmits(5)}
                />
              )}
            />
            <Route render={() => <Redirect to={`/${baseUrl}/step1`} />} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default RegistrationSteps;
