import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { get } from "lodash";

import CardPaymentContainer from "modules/my-cards/containers/CardPaymentContainer";
import SuccessVoucherRecharge from "modules/recharge/modals/SuccessVoucherRecharge";
import { showModal } from "modules/common/components/Modal";

import { useGuestAmounList, useGuestIsLoading } from "../hooks";
import PaymentInfo from "../components/PaymentInfo";
import * as actions from "../actions";

function PayAsGuestContainer() {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const [formData, setFormData] = useState({});
  const [options] = useGuestAmounList();

  const isLoading = useGuestIsLoading();

  const handleBackToHome = () => {
    if (location?.search) {
      history.push(location.pathname);
      return;
    }
    history.push("/");
  };

  const handleSuccessVoucherRecharge = () => {
    showModal({
      align:
        "items-center justify-center bg-gray-800 bg-opacity-50 md:bg-primary-700 md:bg-opacity-100",
      className: "w-5/6 md:w-96 md:rounded-xl",
      content: (close) => (
        <SuccessVoucherRecharge
          returnButtonLabel="Back to Login"
          contentText="Voucher has been recharge to the account."
          onClose={() => {
            close();
            history.push("/");
          }}
        />
      ),
    });
  };

  const handlePayment = (key) => (data) => {
    switch (key) {
      case "select":
        setFormData((prev) => ({ ...prev, ...data }));
        if (!get(data, "amount", 0) && !get(data, "voucher_code", "")) {
          toast.error(
            "You must select an amount/Enter voucher code to continue."
          );
          return;
        } else if (get(data, "payment_method", "") === "voucher") {
          actions.guestPayVoucher({ ...data }, handleSuccessVoucherRecharge);
        } else history.push(`${match.path}?amount=${data.amount}`);
        break;

      case "payment":
        const service_type = get(formData, "service_type", "");
        if (service_type === "PREPAID") {
          actions.guestRechargeAccount({
            ...formData,
            refill_profile_id: options.find(
              (option) => `${option.amount}` === `${formData?.amount}`
            ).refill_profile_id,
          });
        } else if (service_type === "POSTPAID") {
          actions.guestPayPostpaid({ ...formData });
        } else if (service_type === "FIXED LINE") {
          actions.guestPayFixedline({ ...formData });
        } else {
          toast.error("You need to select a service type.");
        }
        break;

      default:
        break;
    }
  };

  const renderSelectPaymentDetails = () => {
    return <PaymentInfo onSubmit={handlePayment("select")} />;
  };

  const renderPaymentMethod = () => {
    return (
      <CardPaymentContainer
        onConfirm={handlePayment("payment")}
        isLoading={isLoading}
        retainLayout
      />
    );
  };

  useEffect(() => {
    actions.listServiceType();
    actions.listPredefinedAmounts();
  }, []);

  return (
    <div className="w-full flex flex-col h-full pt-10 pb-8 relative">
      <div className="fixed z-50 top-0 left-0 bg-white w-full h-full flex flex-col p-4 md:bg-transparent md:static">
        <div>
          <button
            type="button"
            className="shadow-xs px-2 py-1 rounded bg-white text-normal"
            onClick={handleBackToHome}
          >
            <i className="fa fa-arrow-left" /> Back
          </button>
        </div>

        <div className="mt-4 h-full flex flex-col md:px-4">
          {get(location, "search", "")
            ? renderPaymentMethod()
            : renderSelectPaymentDetails()}
        </div>
      </div>
    </div>
  );
}

export default PayAsGuestContainer;
