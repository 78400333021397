import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useApiLoading } from "react-reqq";
import { get } from "lodash";

import FormInput from "modules/common/forms/FormInput";
import DayPicker from "modules/common/forms/DayPicker";

import * as c from "../constants";

function SetAccountDetailsTwo({ initValues, onSubmit }) {
  const history = useHistory();
  const validationSchema = yup.object({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
  });
  const isLoading = useApiLoading(c.ACCOUNT_REGISTRATION, "post");
  const { register, handleSubmit, errors } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!get(initValues, "service_number", ""))
      history.push("/registration/step1");
  }, []);

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Personal Information</h1>
      <p className="text-gray-600 text-sm mt-4">
        Fill out the basic information below.
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit((data) => {
          onSubmit({
            ...data,
            birth_date: moment(data.birth_date).format("MM/DD/YYYY"),
          });
        })}
      >
        <FormInput
          placeholder="First Name"
          name="first_name"
          label="First Name"
          validation={register}
          containerClassName="mb-2"
          error={errors.first_name}
        />

        <FormInput
          placeholder="Last Name"
          name="last_name"
          label="Last Name"
          validation={register}
          containerClassName="mb-2"
          error={errors.last_name}
        />

        <DayPicker label="Date of birth" name="birth_date" ref={register} />

        <button
          disabled={isLoading}
          className="btn primary w-full mx-auto mt-auto"
          type="submit"
        >
          Next
        </button>
      </form>
    </div>
  );
}

SetAccountDetailsTwo.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
};

export default SetAccountDetailsTwo;
