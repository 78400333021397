import React, { useRef } from "react";
import PropTypes from "prop-types";

const allowedCharaters = [
  "(",
  ")",
  "+",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

function FormMobileNumber({
  name,
  label,
  containerClassName,
  inputClassName,
  required,
  type,
  validation,
  error,
  leftIcon,
  rightIcon,
  ...props
}) {
  const mobileInputRef = useRef();

  const handleOnFocus = (e) => {
    const { value } = e.target;
    if (!value) {
      mobileInputRef.current.value = "(+673)";
    }
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    if (value.match(/^\(\+673\)/) === null) {
      mobileInputRef.current.value = "(+673)";
      return;
    }
    const isNumberOnly = /^\(\+673\)[0-9]{0,7}$/.test(value);
    if (!isNumberOnly) {
      const newVal = value
        .split("")
        .filter((char) => allowedCharaters.includes(char))
        .filter((_, i) => i < 13)
        .join("");
      mobileInputRef.current.value = newVal;
      return;
    }
  };

  const handleOnBlur = (e) => {
    const { value } = e.target;
    if (value === "(+673)") {
      mobileInputRef.current.value = "";
    }
  };

  return (
    <div className={`flex flex-col items-start ${containerClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className="text-gray-700 mb-2 font-medium text-sm"
        >
          {label}
          {required && <span className="text-red-600 mr-1">*</span>}
        </label>
      )}
      <div className="flex items-center relative w-full">
        <span className="absolute left-0 ml-4 ">{leftIcon}</span>
        <input
          type={type}
          ref={(el) => {
            validation(el);
            mobileInputRef.current = el;
          }}
          name={name}
          onFocus={handleOnFocus}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          className={`${error ? "border-red-500" : ""} ${
            rightIcon ? "pr-10" : "'"
          }  ${leftIcon ? "pl-10" : "'"} ${inputClassName}`}
          {...props}
        />
        <span className="absolute px-4 right-0">{rightIcon}</span>
      </div>
      {error && (
        <small className="text-xs text-red-500">
          <i className="fa fa-exclamation-circle" /> {error.message}
        </small>
      )}
    </div>
  );
}

FormMobileNumber.defaultProps = {
  label: "",
  error: null,
  containerClassName: "",
  inputClassName:
    "text-gray-900 w-full p-3 rounded-2xl mb-0 border bg-white text-sm mt-2",
  required: false,
  type: "text",
  leftIcon: null,
  rightIcon: null,
};

FormMobileNumber.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  validation: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.instanceOf(Object),
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
};

export default FormMobileNumber;
