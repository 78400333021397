import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import MarkdownRenderer from "modules/common/components/MarkdownRenderer";
import { useOutsideClick } from "modules/common/hooks";

const documents = {
  terms: {
    title: "Terms & Conditions",
    url: "/terms_conditions.md",
  },
  privacy: {
    title: "Privacy Policy",
    url: "/privacy_policy.md",
  },
};

function LegalDocuments({ show, toggle, type }) {
  const containerRef = useRef();
  const [willHide, setWillHide] = useState(false);
  const [cleanListener] = useOutsideClick(containerRef, show, () =>
    setWillHide(true)
  );

  const handleHide = (e) => {
    const { classList = [] } = e.target;
    if (classList.contains("slide-down")) {
      cleanListener();
      setWillHide(false);
      toggle();
    }
  };

  useEffect(() => {
    return cleanListener;
  }, []);
  return (
    <div
      className={`fixed top-0 left-0 z-50 w-full h-full bg-gray-800 bg-opacity-25
        md:absolute md:w-screen md:bg-primary-800 md:bg-opacity-50
        ${!show && "hidden"} 
     `}
    >
      <div
        ref={containerRef}
        className={`fixed w-full h-80vh bottom-0 right-0 bg-white text-gray-800 border
        rounded-t-2xl md:rounded-2xl shadow-xs slide-up flex flex-col
        text-left px-4 py-5 md:absolute md:shadow md:w-4/5 md:m-auto md:top-0 md:left-0 slide-up
        ${willHide ? "slide-down" : ""}
        `}
        onAnimationEnd={handleHide}
      >
        <div className="flex flex-col h-full">
          <h2 className="text-2xl font-semibold text-gray-800">
            {documents[type]?.title || documents.terms.title}
          </h2>
          <div className="overflow-y-scroll border-2 border-gray-400 rounded bg-gray-300 text-gray-700 p-4 md-file">
            <MarkdownRenderer
              url={documents[type]?.url || documents.terms.url}
            />
          </div>
          <button
            type="button"
            className="self-center mt-4 w-full py-2 text-gray-600 border border-gray-600 rounded-2xl
            hover:bg-gray-600 hover:text-white md:w-40 "
            onClick={toggle}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

LegalDocuments.defaultProps = {
  type: "terms",
};

LegalDocuments.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default LegalDocuments;
