import { useCallback } from "react";
import { useApiLoading } from "react-reqq";

import * as actions from "./actions";
import * as c from "./constants";

export const useChangeSecurityPin = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const changePin = useCallback(
    actions.changeSecurityPin(onSuccess, onError),
    []
  );
  const isLoading = useApiLoading(c.SECURITY_CHANGE_PIN, "post");

  return [changePin, isLoading];
};

export const useChangeSecurityPassword = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const changePassword = useCallback(
    actions.changeSecurityPassword(onSuccess, onError),
    []
  );
  const isLoading = useApiLoading(c.SECURITY_CHANGE_PASSWORD, "post");

  return [changePassword, isLoading];
};

export const useValidatePassword = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const validate = useCallback(
    actions.validatePassword(onSuccess, onError),
    []
  );
  const isLoading = useApiLoading(c.VALIDATE_PASSWORD, "post");

  return [validate, isLoading];
};
