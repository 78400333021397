import React, { useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import EmailSentPromt from "../components/EmailSentPromt";
import EnterUserId from "../components/EnterUserId";

import * as actions from "../actions";

function ForgotPasswordContainer() {
  const history = useHistory();
  const match = useRouteMatch();
  const [email, setEmail] = useState("");

  const handlePrevStep = () => {
    history.push("/");
  };

  const handleStepsSubmit = (data) => {
    actions.sendTempPasswordToEmail(data, (email_address) => {
      setEmail(email_address);
      history.push(`${match.path}/success`);
    });
  };

  return (
    <div className="w-full flex flex-col h-full pt-10 pb-8 relative">
      <div className="fixed z-50 top-0 left-0 bg-white w-full h-full flex flex-col p-4 md:bg-transparent md:static">
        <div>
          <Route
            exact
            path={match.path}
            render={() => (
              <button
                className="shadow-xs px-2 py-1 rounded bg-white text-normal"
                onClick={handlePrevStep}
              >
                <i className="fa fa-arrow-left" /> Back
              </button>
            )}
          />
        </div>
        <div className="flex-grow flex flex-col md:px-4">
          <Switch>
            <Route
              exact
              path={match.path}
              render={() => <EnterUserId onSubmit={handleStepsSubmit} />}
            />
            <Route
              path={`${match.path}/success`}
              render={() => <EmailSentPromt email={email} />}
            />
            <Route render={() => <Redirect to={match.path} />} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordContainer;
