import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { createEditor } from "slate";
import { Slate, Editable, withReact, useSelected } from "slate-react";
import { toast } from "react-toastify";

import { LogoPurple } from "modules/common/components/AppIconLogos";

const BASE_WALLET_URL = process.env.REACT_APP_WALLET_EP;

function ProductDescription() {
  const location = useLocation();

  const [product, setProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    product_id: "",
    token: "",
  });

  const editor = useMemo(() => withReact(createEditor()), []);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  useEffect(() => {
    const parsedQuery = queryString.parse(location.search);
    setParams({
      product_id: parsedQuery?.product_id,
      token: parsedQuery?.token,
    });
  }, [location]);

  useEffect(() => {
    setIsLoading(true);
    if (params.product_id || params.token) {
      fetch(
        `${BASE_WALLET_URL}/order/order/v1/mobile/products/${params.product_id}`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return toast.error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data?.data) {
            setProduct(data?.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          throw Error(error);
        });
    }
  }, [params]);

  if (isLoading || isEmpty(product)) {
    return (
      <div className="text-center absolute inset-0 form-background">
        <div className="flex flex-col w-full h-full items-center justify-center">
          <LogoPurple className="w-32" />
          <i className="fas fa-spinner animate-spin text-gray-600" />
        </div>
      </div>
    );
  }

  return (
    <div className="absolute inset-0 bg-white overflow-y-auto hide-scrollbar">
      <div className="w-full max-w-2xl py-3 mx-auto px-3 lg:px-0 md:px-8">
        <Slate
          editor={editor}
          initialValue={
            !isEmpty(product?.description)
              ? JSON.parse(product?.description)
              : []
          }
        >
          <div className="prose max-w-full prose-headings prose-p prose-ol prose-ul prose-img">
            <Editable
              readOnly
              renderElement={renderElement}
              renderLeaf={renderLeaf}
            />
          </div>
        </Slate>
      </div>
    </div>
  );
}

export default ProductDescription;

const Element = (props) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };
  switch (element.type) {
    case "image":
      return <Image {...props} />;
    case "block-quote":
      return (
        <blockquote className="bg-gray-500" style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "paragraph":
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

Element.propTypes = {
  attributes: PropTypes.instanceOf(Object),
  element: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

Leaf.propTypes = {
  attributes: PropTypes.instanceOf(Object),
  leaf: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const Image = ({ attributes, children, element }) => {
  const selected = useSelected();

  return (
    <div {...attributes}>
      <div contentEditable={false} className="relative">
        <img
          src={element.url}
          className={`pointer-events-none block max-w-md ${
            selected ? "ring-2 ring-primary-300" : ""
          }`}
        />
      </div>
      {children}
    </div>
  );
};

Image.propTypes = {
  attributes: PropTypes.instanceOf(Object),
  element: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
