import React, { useState } from "react";
import PropTypes from "prop-types";

function FormPassword({
  name,
  containerClassName,
  inputClassName,
  label,
  error,
  validation,
  required,
  ...rest
}) {
  const [inputType, setInputType] = useState("password");

  const handleTogglePassword = () => {
    if (inputType === "password") setInputType("text");
    else setInputType("password");
  };

  return (
    <div className={`flex flex-col items-start ${containerClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className="text-gray-700 mb-2 font-medium text-sm"
        >
          {label}
          {required && <span className="text-red-600 mr-1">*</span>}
        </label>
      )}
      <div className="flex items-center relative w-full">
        <input
          name={name}
          className={`${error ? "border-red-500" : ""} ${inputClassName}`}
          type={inputType}
          ref={validation}
          {...rest}
        />
        <button
          type="button"
          className="absolute right-0 mr-4 text-sm cursur text-gray-600 hover:text-primary-600"
          aria-hidden="true"
          onClick={handleTogglePassword}
          style={{
            top: "50%",
            transform: "translateY(-30%)",
          }}
        >
          {inputType === "password" ? "Show" : "Hide"}
        </button>
      </div>
      {error && (
        <small className="text-xs text-red-500">
          <i className="fa fa-exclamation-circle" /> {error.message}
        </small>
      )}
    </div>
  );
}

FormPassword.defaultProps = {
  label: "",
  containerClassName: "",
  inputClassName:
    "text-gray-900 w-full p-3 rounded-2xl mb-0 border bg-white text-sm mt-2",
  error: null,
  required: false,
};

FormPassword.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.instanceOf(Object),
  validation: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default FormPassword;
