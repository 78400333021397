import React from "react";
import PropTypes from "prop-types";

import IconConfetti from "assets/img/icon-confetti.svg";

function SuccessVoucherRecharge({ onClose, returnButtonLabel, contentText }) {
  return (
    <div className="h-84 p-4 flex flex-col items-center justify-center">
      <img alt="" src={IconConfetti} />
      <h1 className="mt-4 text-2xl font-semibold text-gray-800 text-center">
        Recharge via Voucher Card Successful!
      </h1>
      <p className="text-sm font-semibold text-center text-gray-800 mt-4">
        {contentText}
      </p>
      <button
        type="button"
        className="mt-auto text-primary-800 text-xl font-medium"
        onClick={onClose}
      >
        {returnButtonLabel}
      </button>
    </div>
  );
}

SuccessVoucherRecharge.defaultProps = {
  returnButtonLabel: "Return to Homepage",
  contentText: "Voucher has been recharge to your account.",
};

SuccessVoucherRecharge.propTypes = {
  onClose: PropTypes.func.isRequired,
  returnButtonLabel: PropTypes.string,
  contentText: PropTypes.string,
};

export default SuccessVoucherRecharge;
