import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { AES } from "crypto-js";

import FormInput from "modules/common/forms/FormInput";
import FormPassword from "modules/common/forms/FormPassword";
import { LogoPurple } from "modules/common/components/AppIconLogos";
import { useFcmToken } from "modules/common/hooks";

import { useLogin } from "../hooks";

const validationSchema = yup.object({
  user_id: yup.string().required("Email Address / User ID is required"),
  password: yup.string().required("Password is required"),
});

function Login() {
  const [login, isLoading] = useLogin();
  const token = useFcmToken();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleLogin = (data) => {
    const user_id = AES.encrypt(data?.user_id, "G9AH9CTYRB1DQR9K").toString();
    const password = AES.encrypt(data?.password, "G9AH9CTYRB1DQR9K").toString();
    login({
      user_id,
      password,
      device: "WEB",
      device_token: token,
    });
  };

  return (
    <div className="pt-10 pb-8 md:px-12 w-full">
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className="flex justify-center md:hidden">
          <LogoPurple className="w-48" />
        </div>
        <h1 className="hidden mb-3 text-gray-900 font-medium text-4xl text-left md:block">
          Welcome to MyDST
        </h1>
        <p className="hidden text-normal text-left mb-6 md:block">
          Providing an experience for you to have everything within the reach of
          your fingertips.
        </p>
        <FormInput
          name="user_id"
          label="Email Address / User ID"
          placeholder="Enter your Email Address or User ID"
          containerClassName="mt-2"
          validation={register}
          error={errors.username}
        />
        <FormPassword
          name="password"
          label="Password"
          placeholder="Enter your Password"
          containerClassName="mt-2"
          validation={register}
          error={errors.password}
        />
        <div className="flex flex-wrap justify-end text-center mt-4 mb-2">
          <Link className="text-gray-800 text-xs" to="/forgot-password">
            I forgot my password
          </Link>
        </div>
        <div className="flex mt-4">
          <button
            type="submit"
            className="btn primary rounded-full w-full mx-auto"
            disabled={isLoading}
          >
            Log In
          </button>
        </div>
      </form>
      <div className="flex justify-center">
        <p className="text-center text-gray-900 text-sm content-center form-divider mt-8">
          <span className="form-line">or</span>
        </p>
      </div>
      <Link
        className="text-center text-brand text-sm mt-8 block"
        to="/pay-as-guest"
      >
        Continue as Guest
      </Link>
      <p className="text-center text-sm mt-6">
        Don't have an account?&nbsp;
        <Link className="text-brand" to="/registration">
          Create account.
        </Link>
      </p>
    </div>
  );
}

export default Login;

// import Cookie from "js-cookie";
// import { req } from "react-reqq";

// import { useForgotMpinSendOtp } from "modules/forgot-credentials/hooks";
// import { fetchAccountInfo } from "modules/account/actions";
// import { showModal } from "modules/common/components/Modal";

// import { useAuth } from "../hooks";
// import LoginMpinModal from "../modals/LoginMpinModal";

// const [isPasswordAuthenticated, isPinAuthenticated] = useAuth();
// const resetLoginData = useCallback(() => {
//   Cookie.remove("_token");
//   req.set(c.AUTHENTICATION, {
//     isPasswordAuthenticated: false,
//     isPinAuthenticated: false,
//   });
// }, []);

// const handleLoginWithPin = ({ response: { data } }) => {
//   showModal({
//     align: "items-center justify-center bg-primary-800 bg-opacity-75",
//     className: "modal-sm",
//     content: (close) => (
//       <LoginMpinModal
//         onClose={() => {
//           resetLoginData();
//           close();
//         }}
//         onSuccess={close}
//         onForgot={() => {
//           history.push("/forgot-pin", { profile_id: data?.id });
//           close();
//         }}
//         profileId={data?.id}
//         emailAddress={data?.attributes?._email_address}
//       />
//     ),
//   });
// };

// useEffect(() => {
//   if (isPasswordAuthenticated && !isPinAuthenticated) {
//     fetchAccountInfo(handleLoginWithPin, resetLoginData);
//   }
// }, [isPasswordAuthenticated, isPinAuthenticated]);
