export const LIST_SEC_QUESTIONS = "LIST/SEC/QUESTIONS";
export const LIST_SERVICE_TYPES = "LIST/SERVICE/TYPES";

export const ACCOUNT_LINKING = "ACCOUNT/LINKING";
export const ACCOUNT_VERIFICATION = "ACCOUNT/VERIFICATION";
export const ACCOUNT_REGISTRATION = "ACCOUNT/REGISTRATION";
export const SET_ACCOUNT_PIN = "SET/ACCOUNT/PIN";

export const REGISTER_SUBSCRIPTION_LIST = "REGISTER/subscription_type_list";
export const REGISTER_SERVICE_LIST = (code) =>
  `REGISTER/service_type_list?subscription_code=${code}`;
