import { get } from "lodash";
import walletReq from "walletReq";

import * as c from "./constants";

export const walletCashInCallback = (payload, onSuccess, onError) => {
  walletReq.post({
    key: c.CASH_IN_CALLBACK,
    url: "/cashin/v1/user/callbacks/transaction",
    payload,
    onSuccess: ({ response: { data } }) => {
      if (data?.attributes?.url) {
        return (window.location.href = get(data, "attributes.url", ""));
      }
      if (typeof onSuccess === "function") onSuccess(data);
    },
    onError: () => {
      if (typeof onError === "function") onError();
    },
  });
};
