import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import IconFailed from "assets/img/icon-failed.svg";

function PaymentFailed() {
  const history = useHistory();
  const location = useLocation();

  const isWebApp = location.pathname?.includes("web_app");

  return (
    <div className="mt-6 p-2 w-11/12 md:w-100 flex flex-col items-center">
      <img alt="failed" src={IconFailed} />
      <h1 className="text-3xl font-semibold">Payment Failed!</h1>
      <p className="font-thin">Please try again later...</p>
      {isWebApp && (
        <button
          className="btn primary w-full mt-20"
          onClick={() => history.push("/")}
        >
          Return to Home Page
        </button>
      )}
    </div>
  );
}

export default PaymentFailed;
