import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import EmailIcon from "assets/img/icon-email.svg";

function EmailSentPromt({ email }) {
  const history = useHistory();
  const atIndex = email.split("").indexOf("@");

  const handleRedirectToLogin = () => {
    history.push("/login");
  };

  useEffect(() => {
    if (!email) history.push("/forgot-password");
  }, [email]);

  return (
    <div className="h-full flex flex-col items-center">
      <img className="w-24 h-24" alt="email" src={EmailIcon} />
      <h1 className="text-3xl font-medium">Email Sent</h1>
      <p className="text-gray-700 text-sm text-center mt-4">
        We sent a temporary password to your email <br />
        <span className="font-semibold">
          {email
            .split("")
            .map((char, i) => {
              if (i <= 2 || char === "@" || i > atIndex) {
                return char;
              }
              return "*";
            })
            .join("")}
        </span>
      </p>
      <p className="text-sm text-center text-gray-700 mt-4">
        Please log in to MyDST Mobile or Web App using your temporary password.
      </p>
      <button
        type="button"
        className="btn primary w-full mx-auto mt-auto"
        onClick={handleRedirectToLogin}
      >
        Confirm
      </button>
    </div>
  );
}

EmailSentPromt.propTypes = {
  email: PropTypes.string.isRequired,
};

export default EmailSentPromt;
