import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import CloseIcon from "assets/img/close.svg";
import { LogoPurple } from "modules/common/components/AppIconLogos";

function SecurityPinModal({
  onClose,
  onSubmit,
  onBack,
  initValue,
  isLoading,
  title,
  description,
}) {
  const [value, setValue] = useState(initValue);

  const handlePinInput = (n) => () => {
    if (value.length === 6) return;
    setValue(`${value}${n}`);
  };

  const handleRemovePin = () => {
    const pin = value.split("");
    pin.pop();
    setValue(pin.join(""));
  };

  const renderPinContainer = useCallback(() => {
    return Array(6)
      .fill()
      .map((_, i) => {
        return (
          <div
            className="border border-primary-400 w-8 h-10 rounded grid place-items-center"
            key={i}
          >
            {value.length - 1 >= i ? (
              <span className="bg-primary-400 w-2 h-2 rounded" />
            ) : (
              ""
            )}
          </div>
        );
      });
  }, [value]);

  return (
    <div className="flex flex-col p-4 items-center relative">
      {isLoading && (
        <div className="absolute inset-0 z-10 grid place-items-center">
          <i className="fa fa-circle text-primary-600 text-3xl animate-ping" />
        </div>
      )}
      {typeof onBack === "function" && (
        <button
          onClick={onBack}
          className="absolute z-20 flex items-center px-2 py-1 text-sm text-gray-700 hover:text-primary-700"
          style={{ top: "1rem", left: "1rem" }}
        >
          <i className="fa fa-angle-left mr-2" />
          Back
        </button>
      )}
      {typeof onClose === "function" && (
        <button
          onClick={onClose}
          className="absolute bg-white p-2 right-0 rounded-full shadow top-0"
          style={{ top: "-10px", right: "-10px" }}
        >
          <img src={CloseIcon} alt="close-icon" />
        </button>
      )}
      <div className="flex justify-center">
        <LogoPurple className="w-20" />
      </div>
      <div className="font-semibold text-center">{title}</div>
      <div className="font-thin text-md text-center">{description}</div>
      <div className="flex items-center justify-around m-auto my-5 w-11/12">
        {renderPinContainer()}
      </div>
      {/* <div className="font-light text-primary-700 text-xs cursor-pointer">
        I forgot my security pin
      </div> */}
      <div className="mt-5 w-full">
        <div className="flex items-center justify-around">
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("1")}
          >
            1
          </button>
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("2")}
          >
            2
          </button>
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("3")}
          >
            3
          </button>
        </div>
        <div className="flex items-center justify-around my-8">
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("4")}
          >
            4
          </button>
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("5")}
          >
            5
          </button>
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("6")}
          >
            6
          </button>
        </div>
        <div className="flex items-center justify-around">
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("7")}
          >
            7
          </button>
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("8")}
          >
            8
          </button>
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("9")}
          >
            9
          </button>
        </div>
        <div className="flex items-center justify-around mt-8">
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handleRemovePin}
          >
            <span className="fa fa-backspace" />
          </button>
          <button
            className="hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full hover:text-white w-10"
            onClick={handlePinInput("0")}
          >
            0
          </button>
          <button
            className="bg-primary-300 hover:bg-primary-700 cursor-pointer flex font-semibold h-10 items-center justify-center rounded-full text-primary-700 hover:text-white w-10"
            disabled={value.length !== 6}
            onClick={() => onSubmit(value)}
          >
            <span className="fa fa-angle-right" />
          </button>
        </div>
      </div>
    </div>
  );
}

SecurityPinModal.defaultProps = {
  initValue: "",
  isLoading: false,
  onBack: null,
  title: "Enter your PIN",
  description: "Please enter current 6-digit PIN",
};

SecurityPinModal.propTypes = {
  initValue: PropTypes.string,
  isLoading: PropTypes.bool,
  onBack: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default SecurityPinModal;
