import React, { useRef } from "react";
import PropTypes from "prop-types";
import { placeValueFormatter } from "../helper";

function FormInputNumber({
  name,
  label,
  containerClassName,
  inputClassName,
  required,
  validation,
  error,
  leftIcon,
  rightIcon,
  formatPlaceValue,
  placeholder,
}) {
  const inputRef = useRef();
  const handleKeyPress = (e) => {
    if (
      (e.charCode >= 48 && e.charCode <= 57) ||
      e.charCode === 44 ||
      e.charCode === 46
    )
      return;
    e.preventDefault();
  };

  return (
    <div className={`flex flex-col items-start ${containerClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className="text-gray-700 mb-2 font-medium text-sm"
        >
          {label}
          {required && <span className="text-red-600 mr-1">*</span>}
        </label>
      )}
      <div className="flex items-center relative w-full">
        <span className="absolute left-0 ml-4">{leftIcon}</span>
        <input
          ref={(el) => {
            validation(el);
            inputRef.current = el;
          }}
          name={name}
          placeholder={placeholder}
          className={`${error ? "border-red-500" : ""} ${
            rightIcon ? "pr-10" : "'"
          }  ${leftIcon ? "pl-10" : "'"} ${inputClassName}`}
          onKeyPress={handleKeyPress}
          onChange={(e) => {
            if (formatPlaceValue) {
              const { value } = e.target;
              inputRef.current.value = placeValueFormatter(
                value.replace(/,/g, "")
              );
            }
          }}
        />
        <span className="absolute px-4 right-0">{rightIcon}</span>
      </div>
      {error && (
        <small className="text-xs text-red-500">
          <i className="fa fa-exclamation-circle" /> {error.message}
        </small>
      )}
    </div>
  );
}

FormInputNumber.defaultProps = {
  label: "",
  containerClassName: "",
  inputClassName:
    "text-gray-900 w-full p-3 rounded-2xl mb-0 border bg-white text-sm mt-2",
  leftIcon: null,
  rightIcon: null,
  required: false,
  error: null,
  formatPlaceValue: false,
  placeholder: "",
};

FormInputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  required: PropTypes.bool,
  validation: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object),
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  formatPlaceValue: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default FormInputNumber;
