import React from "react";
import { Switch, Route } from "react-router-dom";

import { LogoWhite } from "modules/common/components/AppIconLogos";
import AppFooter from "modules/partial/components/AppFooter";
import LoginSliders from "modules/partial/components/LoginSliders";
import BannerWide from "assets/img/banner-wide.svg";

function PublicBanners() {
  return (
    <div className="h-full flex flex-col py-10">
      <div
        className="w-full flex justify-center items-start"
        style={{ flexBasis: "100%" }}
      >
        <LogoWhite className="w-48" />
      </div>
      <div className="flex flex-col" style={{ flexBasis: "100%" }}>
        <div className="w-full flex-grow">
          <Switch>
            <Route
              path={["/registration", "/pay-as-guest"]}
              render={({ match }) => {
                return (
                  <>
                    <div className="w-full py-3 flex justify-center">
                      <img alt="" src={BannerWide} />
                    </div>
                    <div className="title-light mt-3 mb-3 text-xl text-center">
                      <h1 className="text-5xl font-medium tracking-wide">
                        {match.path === "/registration"
                          ? "Register to MyDST"
                          : "Pay as a guest"}
                      </h1>
                    </div>
                  </>
                );
              }}
            />
            <Route component={LoginSliders} />
          </Switch>
        </div>
        <div className="w-full mt-3 text-center footnote-light">
          <AppFooter />
        </div>
      </div>
    </div>
  );
}

export default PublicBanners;
