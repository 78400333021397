import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormInputNumber from "modules/common/forms/FormInputNumber";
import { showModal } from "modules/common/components/Modal";
import { useCountdown } from "modules/common/hooks";

import { useForgotMpinSendOtp, useForgotMpinValidateOtp } from "../hooks";
import ChangeSecurityPin from "../modals/ChangeSecurityPin";

const validationSchema = yup.object({
  otp: yup
    .string()
    .required("OTP code is required")
    .min(6, "OTP code must be atleast 6 digits"),
});

function ForgotMpinContainer() {
  const history = useHistory();
  const location = useLocation();

  const [countdown, reset] = useCountdown(300);
  const [email, setEmail] = useState("");
  const atIndex = useMemo(() => email.split("").indexOf("@"), [email]);

  const [requestOtp, isLoadingOtp] = useForgotMpinSendOtp((responseData) => {
    setEmail(responseData?.attributes?.email_address);
    reset();
  });
  const [validateOtp, isLoadingValidate] = useForgotMpinValidateOtp(() => {
    showModal({
      align: "items-center justify-center bg-primary-800 bg-opacity-75",
      className: "modal-sm",
      content: (close) => (
        <ChangeSecurityPin
          onClose={close}
          onSuccess={() => {
            history.push("/login");
            close();
          }}
        />
      ),
    });
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      profile_id: location?.state?.profile_id,
    },
  });

  const handleOnSubmit = (data) => {
    const payload = { ...data, profile_id: location?.state?.profile_id };
    validateOtp(payload);
  };

  useEffect(() => {
    if (!location?.state?.profile_id) {
      history.push("/login");
      return;
    }
    requestOtp(location?.state);
  }, []);

  return (
    <div className="w-full flex flex-col h-full pt-10 pb-8 relative">
      <div className="fixed z-50 top-0 left-0 bg-white w-full h-full flex flex-col p-4 md:bg-transparent md:static">
        <div>
          <button
            className="shadow-xs px-2 py-1 rounded bg-white text-normal"
            onClick={() => history.push("/login")}
          >
            <i className="fa fa-arrow-left" /> Back
          </button>
        </div>
        {isLoadingOtp && (
          <div className="absolute inset-0 z-10 grid place-items-center">
            <i className="fa fa-circle text-primary-600 text-3xl animate-ping" />
          </div>
        )}
        <div className="h-full flex flex-col md:px-4">
          <h1 className="text-3xl font-medium">
            Forgot Security PIN &gt; Verify your Account
          </h1>
          <p className="text-gray-600 text-sm mt-4 flex items-center">
            Enter the 6 digit OTP sent to{" "}
            {email && !isLoadingOtp ? (
              email
                .split("")
                .map((char, i) => {
                  if (i <= 2 || char === "@" || i > atIndex) {
                    return char;
                  }
                  return "*";
                })
                .join("")
            ) : (
              <span className="ml-2 inline-block h-4 w-24 rounded bg-gray-400 animate-pulse" />
            )}
          </p>
          <form
            className="mt-4 flex flex-col h-full gap-2"
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <FormInputNumber
              placeholder="123456"
              name="otp"
              label="Verification Code"
              validation={register}
              containerClassName="mb-2"
              error={errors?.otp}
            />

            <p className="text-gray-600 text-sm mt-2 flex items-center">
              No OTP Received?
              <span className="ml-2 font-medium">
                <button
                  type="button"
                  className="text-primary-800 disabled:text-primary-400"
                  onClick={() => requestOtp(location?.state)}
                  disabled={isLoadingOtp || isLoadingValidate}
                >
                  Resend a new code
                </button>
              </span>
            </p>
            <p className="mt-1 text-gray-600 text-sm flex items-center">
              Your OTP Validity will expire in
              <span className="ml-2 text-primary-800 font-medium">
                {countdown}
              </span>
            </p>

            <button
              className="mt-auto btn primary w-full mx-auto"
              type="submit"
              disabled={isLoadingValidate}
            >
              Confirm
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotMpinContainer;
