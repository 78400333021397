import React, { useState } from "react";
import PropTypes from "prop-types";
import Captcha from "demos-react-captcha";
import { useForm } from "react-hook-form";
import { useApiLoading } from "react-reqq";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormInput from "modules/common/forms/FormInput";

import * as c from "../constants";

function EnterUserId({ initValues, onSubmit }) {
  const [hasUndergoCaptcha, setHasUndergoCaptcha] = useState(false);
  const validationSchema = yup.object({
    user_id: yup.string().required("User ID/Email address is required"),
  });
  const { register, handleSubmit, errors } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
  });

  const isLoading = useApiLoading(c.PASSWORD_RESET, "post");

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Forgot Password?</h1>
      <p className="text-gray-600 text-sm mt-4">
        Enter your User ID/Email address to search for your account.
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInput
          placeholder="User ID/Email address"
          name="user_id"
          label="Enter your User ID/Email address"
          containerClassName="mb-2"
          validation={register}
          error={errors.user_id}
        />

        <div className="mt-2 flex">
          <Captcha
            onChange={(result) => setHasUndergoCaptcha(result)}
            onRefresh={() => setHasUndergoCaptcha(false)}
            placeholder="Enter captcha"
            length={6}
          />
        </div>

        <button
          className="mt-auto btn primary w-full mx-auto"
          type="submit"
          disabled={isLoading || !hasUndergoCaptcha}
        >
          Confirm
        </button>
      </form>
    </div>
  );
}

EnterUserId.defaultProps = {
  initValues: {},
};

EnterUserId.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
};

export default EnterUserId;
