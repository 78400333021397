import React, { useState } from "react";
import { toast } from "react-toastify";
import { get } from "lodash";

import SecurityPinModal from "modules/common/modals/SecurityPinModal";
import { useChangeSecurityPin } from "modules/security-privacy/hooks";

function ChangeSecurityPin({ onClose, onSuccess }) {
  const [form, setForm] = useState({});
  const [step, setStep] = useState(1);
  const [changePin, isLoading] = useChangeSecurityPin(() => {
    toast.success("PIN successfully changed");
    onSuccess();
  });

  const handleSubmits = (key) => (value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
    if (key === "mpin") {
      return setStep(2);
    }
    if (value !== get(form, "mpin", "")) {
      return toast.error("PIN does not match");
    }
    changePin({ mpin: form.mpin, [key]: value });
  };

  return (
    <>
      {step === 1 && (
        <SecurityPinModal
          onClose={onClose}
          initValue={get(form, "mpin", "")}
          onSubmit={handleSubmits("mpin")}
          isLoading={isLoading}
          title="Enter your New PIN"
          description="Please enter your nominated 6-digit PIN"
        />
      )}
      {step === 2 && (
        <SecurityPinModal
          onClose={onClose}
          onBack={() => setStep(1)}
          initValue={get(form, "confirmation_mpin", "")}
          onSubmit={handleSubmits("confirmation_mpin")}
          isLoading={isLoading}
          title="Confirm your New PIN"
          description="Please re-enter your nominated 6-digit PIN"
        />
      )}
    </>
  );
}

export default ChangeSecurityPin;
