import React from "react";
import app_logo from "assets/img/splash-logo.png";

export function SplashUpdate() {
  return (
    <div className="fixed h-screen w-screen top-0 left-0 z-50 flex bg-primary-800">
      <div className="m-auto flex flex-col items-end animate-pulse">
        <img className="w-72" src={app_logo} alt="splash" />
        <p className="text-white text-2xl font-medium">Updating ...</p>
      </div>
    </div>
  );
}

function Splash() {
  return (
    <div className="fixed h-screen w-screen flex bg-brand">
      <div className="m-auto">
        <img className="animate-pulse w-72" src={app_logo} alt="splash" />
      </div>
    </div>
  );
}

export default Splash;
