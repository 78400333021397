import React, { useState, useEffect } from "react";
import moment from "moment";

import packageJson from "../package.json";
import { SplashUpdate } from "modules/partial/containers/Splash";

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          console.debug("latestVersion: ", latestVersionDate);
          console.debug("currentVersion: ", currentVersionDate);

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    const refreshCacheAndReload = async () => {
      console.debug("doing a hard reload");
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        const cacheKeys = await caches.keys();
        await Promise.all(
          cacheKeys.map((key) => {
            return caches.delete(key);
          })
        );
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : <SplashUpdate />}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
