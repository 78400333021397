import { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { debounce, isEmpty, forOwn, get, omitBy, takeRight } from "lodash";

export const placeValueFormatter = (num = 0) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const placeValueRemover = (str = "") => str.replace(/,/g, "");

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== "boolean" ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const formatDate = (date, format = "MM/DD/YYYY", defaultValue = "-") => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return defaultValue;
  return moment(d).format(format);
};

export const transformIncluded = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === get(z, "type") && y.id === get(z, "id")
            ) || {}
        )
      : included.find(
          (y) => y.type === get(v, "data.type") && y.id === get(v, "data.id")
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const storage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const usePersistState = (key, state, config = {}) => {
  const [value, setValue] = useState(storage.get(key) || state);
  const updateState = useCallback(
    debounce((newState) => {
      storage.set(key, newState);
    }, 500),
    []
  );
  useEffect(() => {
    updateState(value);
  }, [value]); // eslint-disable-line
  useEffect(() => {
    return () => {
      if (!config.keepOnUnmount) {
        storage.remove(key);
      }
    };
  }, []); // eslint-disable-line
  return [value, setValue];
};

export const removePersistState = (key) => {
  storage.remove(key);
};

export const toGeoArr = (str, default_value = false) => {
  const arr = (str || "").split(",").map((l) => +l);
  if (Number.isNaN(Number(arr[0])) || Number.isNaN(Number(arr[1])))
    return default_value;
  return arr;
};

export const toGeoStr = (arr) => arr.join(",");

export const loadAPI = (id, src) =>
  new Promise((cb) => {
    const fjs = document.getElementsByTagName("script")[0];
    const js = document.createElement("script");

    if (document.getElementById(id)) {
      cb();
      return;
    }

    js.id = id;
    js.src = src;
    js.onload = cb;
    fjs.parentNode.insertBefore(js, fjs);
  });

export const focusElement = (id) => {
  try {
    document.getElementById(id).focus();
  } catch (err) {
    // do nothing...
  }
};

export const latLngDistance = (latLngA, latLngB, unit = "M") => {
  const lat1 = get(latLngA, "0") || "undefined";
  const lon1 = get(latLngA, "1") || "undefined";
  const lat2 = get(latLngB, "0") || "undefined";
  const lon2 = get(latLngB, "1") || "undefined";
  if (
    Number.isNaN(Number(lat1)) ||
    Number.isNaN(Number(lat1)) ||
    Number.isNaN(Number(lat1)) ||
    Number.isNaN(Number(lat1))
  )
    return 0;
  if (lat1 === lat2 && lon1 === lon2) return 0;

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist *= 1.609344;
  }
  if (unit === "N") {
    dist *= 0.8684;
  }
  return dist;
};

export const transformIncludedDeep = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map((z) =>
          transformIncludedDeep(
            included.find(
              (y) => y.type === get(z, "type") && y.id === get(z, "id")
            ) || {},
            included
          )
        )
      : transformIncludedDeep(
          included.find(
            (y) => y.type === get(v, "data.type") && y.id === get(v, "data.id")
          ) || {},
          included
        );
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const removeNull = (obj) =>
  omitBy(obj, (x) => typeof x === "undefined" || x === null);

export const removeEmpty = (obj) => omitBy(obj, (x) => isEmpty(`${x}`));

export const abbrNum = (num, dPlaces) => {
  let number = num;
  const decPlaces = 10 ** dPlaces;
  const abbrev = ["k", "M", "B", "T"];
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    const size = 10 ** ((i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i += 1;
      }
      number += abbrev[i];
      break;
    }
  }
  return number;
};

export const formatMobileNumber = (mobile_number) =>
  `673${takeRight(mobile_number, 7).join("")}`;

export const mobileNumberSeparator = (str = "") => {
  if (str === null) return str;
  return [...str]
    .map((char, i) => {
      if (i === 2 || i === 5) {
        return `${char} `;
      }
      return char;
    })
    .join("");
};

export const appendObjectParams = (obj = {}) =>
  `?${Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&")}`;

export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
  return buildDate;
};
