import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import FormRadioButton from "modules/common/forms/FormRadioButton";
import { placeValueFormatter } from "modules/common/helper";

function SelectPaymentMethod({
  amount,
  onSubmit,
  isLoading,
  retainLayout,
  amountLabel,
}) {
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      method: 1,
    },
  });

  return (
    <>
      <h2 className="font-semibold text-2xl md:self-start">
        Select a payment method
      </h2>
      <p className="text-gray-700 text-sm md:self-start">
        The card details is kept private for security.
      </p>

      <form
        className={`mt-4 flex flex-col ${
          retainLayout ? "" : "md:flex-row"
        } w-full h-full gap-2`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={`flex flex-col ${retainLayout ? "" : " md:w-3/5"}`}>
          <FormRadioButton
            name="method"
            label="Credit Card or Debit Card"
            value={1}
            validation={register}
            leftIcon={
              <i className="fa fa-credit-card text-gray-700 text-2xl" />
            }
          />
        </div>
        <div
          className={`bg-white mt-auto w-full p-2 shadow rounded-lg flex flex-col ${
            retainLayout ? "" : "md:h-full md:w-2/5"
          }`}
        >
          <h3 className="font-semibold">Order Details</h3>
          <div className="mt-1 flex font-thin text-gray-700">
            {amountLabel}
            <span className="ml-auto">{`$ ${placeValueFormatter(
              amount
            )}`}</span>
          </div>

          <div className="mt-1 flex text-gray-900">
            Total
            <span className="ml-auto">{`BND ${placeValueFormatter(
              amount
            )}`}</span>
          </div>

          <button
            className={`mt-3 btn primary w-full mx-auto ${
              retainLayout ? "" : "md:mt-auto"
            }`}
            type="submit"
            disabled={isLoading || parseFloat(amount) <= 0}
          >
            Proceed
          </button>
          <button
            className="btn outline mt-2 rounded-2xl"
            type="button"
            onClick={() => {
              history.push("/");
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
}

SelectPaymentMethod.defaultProps = {
  retainLayout: false,
  amountLabel: "Amount",
};

SelectPaymentMethod.propTypes = {
  retainLayout: PropTypes.bool,
  amountLabel: PropTypes.string,
};

export default SelectPaymentMethod;
