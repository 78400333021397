import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { get, takeRight } from "lodash";
import { useForm } from "react-hook-form";
import { useApiLoading } from "react-reqq";

import FormInputNumber from "modules/common/forms/FormInputNumber";
import { useCountdown } from "modules/common/hooks";

import * as actions from "../actions";
import * as c from "../constants";

function VerifyAccount({ initValues, onSubmit, responseErrors, email }) {
  const history = useHistory();

  const { register, handleSubmit } = useForm({
    defaultValues: initValues,
  });
  const resendOtpIsLoading = useApiLoading(c.ACCOUNT_LINKING, "post");
  const isLoading = useApiLoading(c.ACCOUNT_VERIFICATION, "post");

  const [countdown, reset] = useCountdown(300);

  const handleResendOtp = () => {
    actions.accountLinking(initValues, reset);
  };

  useEffect(() => {
    if (!get(initValues, "service_number", "")) {
      history.push("/registration/step1");
    }
  }, [initValues, history]);

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Verify your Account</h1>
      <p className="text-gray-600 text-sm mt-4">
        Enter the 6 digit OTP sent to{" "}
        <span className="text-black font-semibold">
          {initValues?.service_type === "Freedom" ||
          initValues?.service_type === "Infinity"
            ? `${email.substr(0, 3)}*****@${email.substr(
                email.indexOf("@") + 1
              )}`
            : `+673 **** ${
                initValues?.service_number
                  ? takeRight(initValues?.service_number.split(""), 3).join("")
                  : ""
              }`}
        </span>
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInputNumber
          placeholder="123456"
          name="otp"
          label="Verification Code"
          validation={register}
          error={get(responseErrors, "otp", null)}
        />
        <p className="text-gray-600 text-sm mt-2 flex items-center">
          No OTP Received?
          <span className="ml-2 font-medium">
            <button
              type="button"
              className="text-primary-800 disabled:text-primary-400"
              onClick={handleResendOtp}
              disabled={resendOtpIsLoading}
            >
              Resend a new code
            </button>
          </span>
        </p>
        <p className="mt-1 text-gray-600 text-sm flex items-center">
          Your OTP Validity will expire in
          <span className="ml-2 text-primary-800 font-medium">{countdown}</span>
        </p>

        <button
          disabled={isLoading}
          className="btn primary w-full mx-auto mt-auto"
          type="submit"
        >
          Next
        </button>
      </form>
    </div>
  );
}

VerifyAccount.defaultProp = {
  email: "",
};

VerifyAccount.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  email: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  responseErrors: PropTypes.instanceOf(Object),
};

export default VerifyAccount;
