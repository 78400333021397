import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormPinInput from "modules/common/forms/FormPinInput";

function SetSecurityPin({ initValues, onSubmit }) {
  const validationSchema = yup.object({
    mpin: yup.string().length(6, "PIN must be exactly 6 characters"),
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initValues,
  });

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Set Security PIN</h1>
      <p className="text-gray-600 text-sm mt-4">
        You will use this 6 digit PIN to login next time
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormPinInput
          name="mpin"
          validation={register}
          numbersOnly
          error={errors.mpin}
          mask
        />

        <button className="btn primary w-full mx-auto mt-auto" type="submit">
          Register PIN
        </button>
      </form>
    </div>
  );
}

SetSecurityPin.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
};

export default SetSecurityPin;
