import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";

const DrawerContext = createContext();

export function Drawer() {
  const { drawerData, setDrawerData } = useContext(DrawerContext);

  const handleHideDrawer = useCallback((e) => {
    const { classList = [] } = e.target;
    if (classList.contains("fade-out")) {
      setDrawerData((prev) => ({
        ...prev,
        willHideDrawer: false,
        show: false,
      }));
    }
  }, []);

  return (
    <>
      <div
        className={`drawer-backdrop 
	${drawerData?.willHideDrawer ? "fade-out" : ""}
	${!drawerData?.show ? "hidden" : ""}`}
        onAnimationEnd={handleHideDrawer}
        onClick={() =>
          setDrawerData((prev) => ({ ...prev, willHideDrawer: true }))
        }
      />
      <div
        className={`drawer-container bg-white h-full
	${drawerData.size}
	${drawerData?.show ? "slide-in" : ""} 
	`}
      >
        {typeof drawerData?.content === "function"
          ? drawerData.content(drawerData?.state)
          : drawerData.content}
      </div>
    </>
  );
}

export function DrawerProvider({ children }) {
  const [drawerData, setDrawerData] = useState({});
  return (
    <DrawerContext.Provider value={{ drawerData, setDrawerData }}>
      <Drawer />
      {children}
    </DrawerContext.Provider>
  );
}

export const useShowDrawer = (state) => {
  const prevStateRef = useRef();
  const { drawerData, setDrawerData } = useContext(DrawerContext);

  useEffect(() => {
    if (JSON.stringify(prevStateRef.current) === JSON.stringify(state)) return;
    prevStateRef.current = state;
    setDrawerData((prev) => ({ ...prev, state }));
  }, [state, setDrawerData]);

  const showDrawer = useCallback(
    ({ size = "sm", content = () => null }) => {
      const hideDrawer = () => {
        setDrawerData((prev) => ({
          ...prev,
          willHideDrawer: true,
        }));
      };

      setDrawerData((prev) => ({
        ...prev,
        show: true,
        size: size ?? "sm",
        willHideDrawer: false,
        content: content(hideDrawer),
      }));
    },
    [drawerData, setDrawerData]
  );

  return showDrawer;
};
