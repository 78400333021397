import { req } from "react-reqq";

import * as c from "./constants";

export const changeSecurityPin = (onSuccess, onError) => (payload) => {
  req.post({
    key: c.SECURITY_CHANGE_PIN,
    url: "/api/v2/user/subscriber/change_mpin",
    payload,
    onSuccess,
    onError,
  });
};

export const changeSecurityPassword = (onSuccess, onError) => (payload) => {
  req.post({
    key: c.SECURITY_CHANGE_PASSWORD,
    url: "/api/v2/user/subscriber/change_password",
    payload,
    onSuccess,
    onError,
  });
};

export const validatePassword = (onSuccess = () => {}, onError = () => {}) => (
  payload
) => {
  req.post({
    key: c.VALIDATE_PASSWORD,
    url: "/api/v2/user/subscriber/confirm_old_password",
    payload,
    onSuccess,
    onError,
  });
};
