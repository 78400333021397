import React from "react";
import PropTypes from "prop-types";

function FormInput({
  name,
  type,
  label,
  containerClassName,
  inputClassName,
  required,
  validation,
  error,
  leftIcon,
  rightIcon,
  disabled,
  ...props
}) {
  return (
    <div className={`flex flex-col items-start ${containerClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className="text-gray-700 mb-2 font-medium text-sm"
        >
          {label}
          {required && <span className="text-red-600 mr-1">*</span>}
        </label>
      )}
      <div className="flex items-center relative w-full">
        <span className="absolute left-0 ml-4 ">{leftIcon}</span>
        <input
          ref={validation}
          name={name}
          type={type}
          className={`${error ? "border-red-500" : ""} ${
            rightIcon ? "pr-10" : "'"
          }  ${leftIcon ? "pl-10" : "'"} ${inputClassName}`}
          {...props}
          disabled={disabled}
          style={{
            backgroundColor: disabled ? "#F5F5F5" : "#FFFFFF",
          }}
        />
        <span className="absolute px-4 right-0">{rightIcon}</span>
      </div>
      {error && (
        <small className="text-xs text-red-500">
          <i className="fa fa-exclamation-circle" /> {error.message}
        </small>
      )}
    </div>
  );
}

FormInput.defaultProps = {
  label: "",
  containerClassName: "",
  inputClassName:
    "text-gray-900 w-full p-3 rounded-2xl mb-0 border text-sm mt-2",
  leftIcon: null,
  rightIcon: null,
  required: false,
  error: null,
  type: "text",
  disabled: false,
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  required: PropTypes.bool,
  validation: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object),
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  disabled: PropTypes.bool,
};

export default FormInput;
