import { req } from "react-reqq";

function walletReq() {
  const defaultOptions = (options) => ({
    ...options,
    headers: (headers) => ({
      ...headers,
      //    Todo
      //    Authorization: Bearer Other Token
    }),
    url: () => `${process.env.REACT_APP_WALLET_EP}${options.url}`,
  });
  return Object.keys(req).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: (options = {}) => req[cur]({ ...defaultOptions(options) }),
    }),
    {}
  );
}

export default walletReq();
