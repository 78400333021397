export const transformSubscriptionTypeList = (response) => {
  return response?.data?.map((type) => ({
    type: type?.type || "",
    id: type?.id || "",
    ...(type?.attributes || {}),
  }));
};

export const transformServiceTypeList = (response) => {
  return response?.data?.map((type) => ({
    type: type?.type || "",
    id: type?.id || "",
    ...(type?.attributes || {}),
  }));
};
