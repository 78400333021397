import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useApiLoading } from "react-reqq";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { mobileNumberWithPrefixRegex } from "modules/common/regexList";
import { formatMobileNumber } from "modules/common/helper";
import FormSelect from "modules/common/forms/FormSelect";
import FormMobileNumber from "modules/common/forms/FormMobileNumber";

import { useSubscriptionTypeList, useServiceTypeList } from "../hooks";
import LegalDocuments from "../../partial/modals/LegalDocuments";
import * as c from "../constants";

const validationSchema = yup.object({
  subscription_type: yup.string().required("Subscription type is required."),
  service_type: yup.string().required("Service type is required."),
  service_number: yup
    .string()
    .required("Service number is required.")
    .matches(
      mobileNumberWithPrefixRegex,
      "Please input a valid mobile number."
    ),
});

function SetServiceInfo({ initValues, onSubmit }) {
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [documentType, setDocumentType] = useState("terms");
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
  });

  const subscription_type = watch("subscription_type");
  // const serviceTypes = useApiGet(c.LIST_SERVICE_TYPES, []);
  const isLoading = useApiLoading(c.ACCOUNT_LINKING, "post");
  const [subscriptionTypes, isLoadingSubscriptions] = useSubscriptionTypeList();
  const [serviceTypes, isLoadingServices] = useServiceTypeList(
    subscription_type
  );

  const toggleDocumentModal = (type) => () => {
    setDocumentType(type);
    setIsShowingModal(!isShowingModal);
  };

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Service Information</h1>
      <p className="text-gray-600 text-sm mt-4">
        Link your service number to get started.
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit((data) => {
          onSubmit({
            ...data,
            service_type_id: serviceTypes.find(
              (type) => type.name === data?.service_type
            ).id,
            service_number: formatMobileNumber(data.service_number),
          });
        })}
      >
        <FormSelect
          name="subscription_type"
          label="Subscription Type"
          validation={register}
          includeBlank="Select Type"
          choices={subscriptionTypes.map((type) => ({
            label: type?.name,
            value: type?.code,
          }))}
          error={errors.subscription_type}
          isLoading={isLoadingSubscriptions}
        />
        {!!subscription_type && (
          <FormSelect
            name="service_type"
            label="Service Type"
            validation={register}
            includeBlank="Select Type"
            choices={serviceTypes.map((type) => ({
              label: type?.name,
              value: type?.name,
            }))}
            error={errors.service_type}
            isLoading={isLoadingServices}
          />
        )}
        <FormMobileNumber
          name="service_number"
          label="Service Number"
          placeholder="(+673)XXXXXXX"
          validation={register}
          error={errors.service_number}
        />

        <p className="text-gray-600 text-xs mt-2">
          This will be your default account, you can link multiple accounts
          later and access them in one place.
        </p>

        <p className="text-gray-600 text-xs mt-auto">
          By clicking 'Submit', I hereby acknowledge to understand and agree to{" "}
          <span
            type="button"
            className="text-primary-800 font-medium cursor-pointer"
            onClick={toggleDocumentModal("terms")}
          >
            Terms &amp; Conditions
          </span>{" "}
          and{" "}
          <span
            type="button"
            className="text-primary-800 font-medium cursor-pointer"
            onClick={toggleDocumentModal("privacy")}
          >
            {" "}
            Privacy Policy
          </span>{" "}
          of DST.
        </p>

        {isShowingModal && (
          <LegalDocuments
            show={isShowingModal}
            toggle={toggleDocumentModal("")}
            type={documentType}
          />
        )}

        <button
          disabled={isLoading}
          className="btn primary w-full mx-auto mt-4"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

SetServiceInfo.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
};

export default SetServiceInfo;
