import React, { useMemo } from "react";
import dayjs from "dayjs";
import { get } from "lodash";

import { formatNumber } from "modules/common/helper";
import GenericView, { ViewItem } from "modules/common/components/GenericView";

function PaymentDetails({ paymentData }) {
  const isPrepaid = useMemo(() => {
    return [
      "PREPAID-RECHARGE",
      "PREPAID-PAY-GUEST",
      "PREPAID-PAY-FRIEND",
    ].includes(get(paymentData, "order_description", ""));
  }, [paymentData]);

  const paymentDate = useMemo(
    () =>
      paymentData?.updated_at ??
      paymentData?.payment_date ??
      dayjs.tz(dayjs(), "Asia/Manila").format("DD/MM/YYYY HH:mm:ss"),
    [paymentData]
  );

  const orderId = useMemo(() => {
    const rawValue = paymentData?.order_id;
    if (!rawValue) return "--";
    const splittedValues = rawValue?.split("-");
    const value = splittedValues?.pop();
    return value;
  }, [paymentData]);

  return (
    <div className="w-full">
      <GenericView data={{ method: "Card" }}>
        <ViewItem
          label="Payment Date"
          name={() => paymentDate}
          className="border-t border-dotted w-full border-black"
          labelClassName="whitespace-no-wrap pb-2 pt-4 text-md"
          valueClassName="text-right pb-2 pt-4 text-md whitespace-no-wrap"
        />
        <ViewItem
          label="Status"
          name={() => <p className="text-secondary-800">Successful</p>}
          labelClassName="whitespace-no-wrap py-2 text-md"
          valueClassName="text-right py-2 text-md"
        />
        {paymentData?.reference_number && (
          <ViewItem
            label="Reference No"
            name={() => get(paymentData, "reference_number", "--")}
            labelClassName="whitespace-no-wrap py-2 text-md"
            valueClassName="text-right break-all py-2 text-md"
          />
        )}
        <ViewItem
          label="Payment Method"
          name={() => get(paymentData, "payment_method", "Card")}
          labelClassName="whitespace-no-wrap py-2 text-md"
          valueClassName="text-right py-2 text-md"
        />
        {paymentData?.wallet_accno && (
          <ViewItem
            label="MyDST Wallet Account No."
            name={() => paymentData?.wallet_accno}
            className="border-t border-dotted w-full border-black"
            labelClassName="pb-2 pt-4 text-md"
            valueClassName="text-right pb-2 pt-4 break-all text-md"
          />
        )}
        {paymentData?.order_id && (
          <ViewItem
            label="Order ID"
            name={() => orderId}
            labelClassName="whitespace-no-wrap py-2 text-md"
            valueClassName="text-right py-2 break-all text-md"
          />
        )}
        {paymentData?.account_number && (
          <ViewItem
            label="Account Number"
            name={() => get(paymentData, "account_number")}
            className="border-t border-dotted w-full border-black"
            labelClassName="whitespace-no-wrap text-md"
            valueClassName="text-right py-4 text-md"
          />
        )}
        <ViewItem
          label="Total Transaction"
          name={() => (
            <>
              {paymentData?.amount
                ? `${get(paymentData, "currency", "$")} ${formatNumber(
                    `${get(paymentData, "amount", 0)}`
                  )}`
                : "--"}
            </>
          )}
          className="border-t border-dotted w-full border-black"
          labelClassName="whitespace-no-wrap pb-2 pt-4 text-md"
          valueClassName="text-right pb-2 pt-4 font-semibold"
        />
      </GenericView>
    </div>
  );
}

export default PaymentDetails;
