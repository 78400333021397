import { req } from "react-reqq";
import { toast } from "react-toastify";
import { get } from "lodash";

import { transformIncluded, appendObjectParams } from "modules/common/helper";
import history from "setup/history";

import * as c from "./constants";

export const listServiceType = (params) => {
  req.get({
    key: c.GET_SERVICES,
    url: `/api/v1/service_types`,
    params,
    transform: (res) => {
      return (res.data || []).map((item) =>
        transformIncluded(item, res.included || [])
      );
    },
  });
};

export const listPredefinedAmounts = () => {
  req.get({
    key: c.RECHARGE_PRE_AMOUNTS,
    url: "/api/v1/amount_recharges_pay_guest",
    transform: (res) => {
      return get(res, "data", []).map(
        ({ attributes: { amount, refill_profile_id } }) => ({
          amount,
          refill_profile_id,
        })
      );
    },
  });
};

export const guestRechargeAccount = (payload) => {
  const params = {
    is_webapp: 1,
    platform: "WEB",
  };
  req.post({
    key: c.RECHARGE_ACCOUNT,
    url: `/api/v1/pay_guests/prepaids/recharges${appendObjectParams(params)}`,
    payload,
    onSuccess: ({ response }) => {
      window.location.href = get(response, "data.attributes.url", "");
    },
  });
};

export const guestPayVoucher = (payload, onSuccess) => {
  req.post({
    key: c.PAY_VOUCHER,
    url: `/api/v1/pay_guests/prepaids/recharges`,
    payload,
    onSuccess: (res) => {
      toast(get(res, "response.message") || "Payment Successful!");
      history.push("/");
      if (onSuccess) onSuccess(res);
    },
  });
};

export const guestPayPostpaid = (payload, onSuccess) => {
  const params = {
    is_webapp: 1,
    platform: "WEB",
  };
  req.post({
    key: c.PAY_POSTPAID,
    url: `/api/v1/pay_guests/postpaids/transactions${appendObjectParams(
      params
    )}`,
    payload,
    onSuccess: ({ response }) => {
      window.location.href = get(response, "data.attributes.url", "");
    },
  });
};

export const guestPayFixedline = (payload, onSuccess) => {
  const params = {
    is_webapp: 1,
    platform: "WEB",
  };
  req.post({
    key: c.PAY_FIXEDLINE,
    url: `/api/v1/pay_guests/infinity/transactions${appendObjectParams(
      params
    )}`,
    payload,
    onSuccess: ({ response }) => {
      window.location.href = get(response, "data.attributes.url", "");
    },
  });
};
