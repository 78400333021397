import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { get, isEmpty } from "lodash";

import SelectPaymentMethod from "../components/SelectPaymentMethod";

function CardPaymentContainer({
  onConfirm,
  isLoading,
  propData,
  retainLayout,
  amountLabel,
}) {
  const location = useLocation();
  const data = queryString.parse(get(location, "search", ""));

  const [formData, setFormData] = useState({ ...data });
  const handleSubmit = (methodData) => {
    setFormData((prev) => ({ ...prev, ...methodData }));
    onConfirm({
      ...formData,
      amount: isEmpty(propData) ? data.amount : propData.amount,
    });
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      <SelectPaymentMethod
        onSubmit={handleSubmit}
        amount={isEmpty(propData) ? data.amount : propData.amount}
        isLoading={isLoading}
        retainLayout={retainLayout}
        amountLabel={amountLabel}
      />
    </div>
  );
}

CardPaymentContainer.defaultProps = {
  propData: {},
  retainLayout: false,
  amountLabel: "Amount",
};

CardPaymentContainer.propTypes = {
  propData: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  retainLayout: PropTypes.bool,
  amountLabel: PropTypes.string,
};

export default CardPaymentContainer;
