import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { formatDate } from "../helper";
// import ShowImage from "modules/riders/modals/ ShowImage";
// import { showModal } from "./Modal";
// import Img from './Img';

const ViewContext = React.createContext();

export const renderArray = (path) => (row) => {
  return (_.get(row, path) || []).join(", ");
};

export const renderDate = (path) => (row) => {
  return formatDate(_.get(row, path));
};

export const ViewItem = ({
  label,
  name,
  className,
  labelClassName,
  valueClassName,
}) => {
  const { data, labelWidth, isLoading } = React.useContext(ViewContext);
  const val =
    typeof _.get(data, name) === "undefined" ? "-" : _.get(data, name);
  const value = typeof name === "function" ? name(data) : val;

  return (
    <tr className={className}>
      <td
        className={`text-sm font-medium text-gray-800 ${labelClassName}`}
        width={labelWidth}
      >
        {label}
      </td>
      <td className={`text-sm text-gray-800 ${valueClassName}`}>
        {isFinite(name) ? (
          <span>{name}</span>
        ) : (
          <>
            {isLoading ? (
              <div className="ml-auto w-20 h-4 rounded bg-gray-300 animate-pulse" />
            ) : (
              <>
                {(_.isEmpty(`${value}`) || typeof value === "object") &&
                typeof name !== "function" ? (
                  <span>&nbsp;</span>
                ) : (
                  value
                )}
              </>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

ViewItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.string,
};

ViewItem.defaultProps = {
  className: "",
  labelClassName: "text-sm",
  valueClassName: "text-sm",
};

export const ViewBoolItem = ({ label, name, className }) => {
  const { data, labelWidth } = React.useContext(ViewContext);
  const val =
    typeof _.get(data, name) === "undefined" ? "-" : _.get(data, name);
  const value = typeof name === "function" ? name(data) : val;
  const boolValue =
    value === 1 ? (
      <span className="text-green-600 font-medium">Yes</span>
    ) : (
      <span className="text-red-600 font-medium">No</span>
    );
  return (
    <tr>
      <td className="font-medium" width={labelWidth}>
        <small>{label}</small>
      </td>
      <td className={`${className} text-sm text-gray-800 leading-snug`}>
        {(_.isEmpty(`${value}`) || typeof value === "object") &&
        typeof name !== "function" ? (
          <span>&nbsp;</span>
        ) : (
          boolValue
        )}
      </td>
    </tr>
  );
};

ViewBoolItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
};

ViewBoolItem.defaultProps = {
  className: "font-thin",
};

export const ViewImage = ({ label, name, className }) => {
  const { data, labelWidth } = React.useContext(ViewContext);
  const val =
    typeof _.get(data, name) === "undefined" ? "-" : _.get(data, name);
  const value = typeof name === "function" ? name(data) : val;

  // const handleViewImage = (img) => {
  //   showModal({
  //     align: "justify-center",
  //     className: "modal-lg",
  //     withTitleClose: true,
  //     content: (onClose) => <ShowImage img={img} onClose={onClose} />,
  //   });
  // };
  return (
    <tr>
      <td className="font-medium" width={labelWidth} valign="top">
        <small>{label}</small>
      </td>
      <td className={`${className}`}>
        {(_.isEmpty(`${value}`) || typeof value === "object") &&
        typeof name !== "function" ? (
          <span>&nbsp;</span>
        ) : (
          value !== "-" && (
            <button
              className="text-sm font-medium text-brand"
              type="button"
              // onClick={() => handleViewImage(value)}
            >
              View Image
            </button>
          )
        )}
        {value === "-" && (
          <p className="text-sm font-medium text-brand">No Image Uploaded</p>
        )}
      </td>
    </tr>
  );
};

ViewImage.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
};

ViewImage.defaultProps = {
  className: "font-thin",
};

export const ViewDivider = ({ label }) => {
  return (
    <tr>
      <td>
        <b className="text-gray-800">{label}</b>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
};

ViewDivider.propTypes = {
  label: PropTypes.string,
};

ViewDivider.defaultProps = {
  label: PropTypes.defaultProps,
};

function GenericView({ data, labelWidth, children, isLoading }) {
  return (
    <ViewContext.Provider value={{ data, labelWidth, isLoading }}>
      <table className="table-auto w-full">
        <tbody>{children}</tbody>
      </table>
    </ViewContext.Provider>
  );
}

GenericView.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object),
  labelWidth: PropTypes.string,
  isLoading: PropTypes.bool,
};

GenericView.defaultProps = {
  children: null,
  labelWidth: "auto",
  isLoading: false,
};

export default React.memo(GenericView);
