import { req } from "react-reqq";

import * as c from "./constants";

export const setNewPassword = (payload, callback) => {
  req.post({
    key: c.SECURITY_PASSWORD,
    url: "/api/v2/user/subscriber/change_temp_password",
    payload,
    onSuccess: () => {
      callback();
    },
  });
};

export const setPin = (payload, callback) => {
  req.post({
    key: c.SECURITY_PIN,
    url: "/api/v2/user/subscriber/change_mpin",
    payload,
    onSuccess: () => {
      callback();
    },
  });
};
