import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";

const ExampleCustomInput = forwardRef(
  ({ value, name, onClick, disabled }, ref) => {
    return (
      <button onClick={onClick} className="mt-2 w-full relative" type="button">
        <input
          name={name}
          ref={ref}
          className="flex items-center w-full p-3 rounded-2xl mb-0 border text-sm text-gray-900"
          style={{
            backgroundColor: disabled ? "#F5F5F5" : "transparent",
          }}
          value={moment(value).format("MMMM DD, yy")}
          onChange={() => {}}
          disabled={disabled}
        />
        <i
          className="absolute right-0 mr-4 my-auto fa fa-angle-down"
          style={{
            top: "50%",
            transform: "translateY(-50%)",
            cursor: disabled ? "default" : "pointer",
          }}
        />
      </button>
    );
  }
);

ExampleCustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

const DayPicker = forwardRef(({ name, label, disabled }, validation) => {
  const [date, setDate] = useState(moment().toDate());

  return (
    <>
      <div className="flex flex-col w-full">
        {label && (
          <label className="text-gray-700 mb-2 font-medium text-sm">
            {label}
          </label>
        )}
        <DatePicker
          name={name}
          customInput={
            <ExampleCustomInput
              name={name}
              ref={validation}
              disabled={disabled}
            />
          }
          customInputRef={validation}
          selected={new Date(date)}
          onChange={(newDate) => setDate(newDate)}
          maxDate={moment().toDate()}
          showYearDropdown
          showMonthDropdown
          disabled={disabled}
        />
      </div>
    </>
  );
});

DayPicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

DayPicker.defaultProps = {
  label: "",
  disabled: false,
};

export default DayPicker;
