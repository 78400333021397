import { req } from "react-reqq";
import { get } from "lodash";

import { appendObjectParams } from "modules/common/helper";

import * as c from "./constants";

export const postpaidListInvoice = (account_number) => {
  const params = {
    type: "monthly",
  };
  req.get({
    key: c.GET_POSTPAID_INVOICE,
    url: `/api/v1/postpaids/${account_number}/invoices`,
    params,
    transform: ({ data: { attributes } }) => attributes,
  });
};

export const getPostpaidDownloadInvoiceLink = (account_number, invoice_id) => {
  req.get({
    key: c.DOWNLOAD_POSTPAID_INVOICE,
    url: `/api/v1/postpaids/${account_number}/invoices/${invoice_id}/download_invoice`,
  });
};

export const getPostpaidInvoiceGraph = (account_number) => {
  const params = {
    module: "graph",
  };
  req.get({
    key: c.GET_POSTPAID_INVOICE_GRAPH,
    url: `/api/v1/postpaids/${account_number}/invoices`,
    params,
    transform: ({ data: { attributes } }) => attributes,
  });
};

export const selectPostpaidInvoice = (account_number, invoice_id) => {
  req.get({
    key: c.SELECTED_POSTPAID_INVOICE,
    url: `/api/v1/postpaids/${account_number}/invoices/${invoice_id}`,
    // transform: ({ data: { attributes } }) => { attributes },
    transform: ({ data: { attributes } }) => {
      return attributes[0];
    },
  });
};

export const paySubscriptionBill = (account_number, payload) => {
  const params = {
    is_webapp: 1,
    platform: "WEB",
  };
  req.post({
    key: c.PAY_SUBSCRIPTION_BILL,
    url: `/api/v1/postpaids/${account_number}/transactions${appendObjectParams(
      params
    )}`,
    payload,
    onSuccess: ({ response }) => {
      window.location.href = get(response, "data.attributes.url", "");
    },
  });
};

export const paySubscriptionBillCallback = (payload, onSuccess, onError) => {
  req.post({
    key: c.PAY_SUBSCRIPTION_BILL_CALLBACK,
    url: "/api/v1/callbacks/postpaids/recharges",
    payload,
    onSuccess: ({ response: { data } }) => {
      if (data?.attributes?.url) {
        return (window.location.href = get(data, "attributes.url", ""));
      }
      if (typeof onSuccess === "function") onSuccess(data);
    },
    onError: () => {
      if (typeof onError === "function") onError();
    },
  });
};

export const postpaidPaymentHistory = (account_number) => {
  req.get({
    key: c.POSTPAID_PAYMENT_HISTORY,
    url: `/api/v1/postpaids/${account_number}/payment_history`,
    transform: ({ data: { attributes } }) => attributes,
  });
};

export const fixedlineListInvoice = (subscription_id) => {
  req.get({
    key: c.GET_FIXEDLINE_INVOICE,
    url: `/api/v1/infinity/${subscription_id}/list_invoice`,
    transform: ({ data: { attributes } }) => attributes,
  });
};

export const getFixedlineInvoiceGraph = (subscription_id) => {
  const params = { module: "graph" };
  req.get({
    key: c.GET_FIXEDLINE_INVOICE_GRAPH,
    url: `/api/v1/infinity/${subscription_id}/list_invoice`,
    params,
    transform: ({ data: { attributes } }) => attributes,
  });
};

export const selectFixedlineInvoice = (account_number, invoice_id) => {
  req.get({
    key: c.SELECTED_FIXEDLINE_INVOICE,
    url: `/api/v1/infinity/${account_number}/show_invoice/${invoice_id}`,
    transform: ({ data: { attributes } }) => {
      return attributes[0];
    },
  });
};

export const getFixedlineDownloadInvoiceLink = (invoice_id) => {
  req.get({
    key: c.DOWNLOAD_FIXEDLINE_INVOICE,
    url: `/api/v1/infinity/${invoice_id}/download_invoice`,
  });
};

export const fixedlinePaymentHistory = (subscription_id) => {
  req.get({
    key: c.GET_FIXEDLINE_PAYMENT_HISTORY,
    url: `/api/v1/infinity/${subscription_id}/get_payment_history`,
    transform: ({ data: { attributes } }) => attributes,
  });
};

export const payFixedlineBill = (subscription_id, payload) => {
  const params = {
    is_webapp: 1,
    platform: "WEB",
  };
  req.post({
    key: c.PAY_SUBSCRIPTION_BILL,
    url: `/api/v1/infinity/${subscription_id}/transactions${appendObjectParams(
      params
    )}`,
    payload,
    onSuccess: ({ response }) => {
      window.location.href = get(response, "data.attributes.url", "");
    },
  });
};

// fixed line callback

export const payFixedlineCallback = (payload, onSuccess, onError) => {
  req.post({
    key: c.PAY_FIXEDLINE_CALLBACK,
    url: "/api/v1/callbacks/infinity/transactions",
    payload,
    onSuccess: ({ response: { data } }) => {
      if (data?.attributes?.url) {
        return (window.location.href = get(data, "attributes.url", ""));
      }
      if (typeof onSuccess === "function") onSuccess(data);
    },
    onError: () => {
      if (typeof onError === "function") onError();
    },
  });
};
