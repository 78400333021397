import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

function FormSelect({
  name,
  label,
  containerClassName,
  labelClassName,
  inputClassName,
  includeBlank,
  choices,
  required,
  validation,
  error,
  isLoading,
  ...props
}) {
  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
          {required && <span className="text-red-600 mr-1">*</span>}
        </label>
      )}
      <div className="relative w-full mt-2 rounded-2xl mb-0 border overflow-hidden">
        <select
          defaultValue={includeBlank && ""}
          ref={validation}
          name={name}
          className={`${error ? "border-red-500" : ""} ${inputClassName}`}
          {...props}
        >
          {isLoading && isEmpty(choices) ? (
            <option value="" disabled>
              Loading...
            </option>
          ) : (
            <>
              {includeBlank && (
                <option value="" disabled>
                  {includeBlank}
                </option>
              )}
              {choices &&
                choices.map((choice, i) => (
                  <option key={`${choice.value}-${i}`} value={choice.value}>
                    {choice.label}
                  </option>
                ))}
            </>
          )}
        </select>
        <div className="absolute flex items-center justify-center top-0 right-0 h-full w-10 bg-white pointer-events-none">
          <i className="fa fa-sort-down text-gray-700" />
        </div>
      </div>
      {error && (
        <small className="text-xs text-red-500">
          <i className="fa fa-exclamation-circle" /> {error.message}
        </small>
      )}
    </div>
  );
}

FormSelect.defaultProps = {
  label: "",
  containerClassName: "flex flex-col items-start w-full",
  labelClassName: "text-gray-700 font-medium text-sm mb-2",
  inputClassName:
    "text-gray-900 border-0 w-full p-3 mb-0 bg-transparent text-sm bg-white",
  includeBlank: "",
  required: false,
  error: null,
  isLoading: false,
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  includeBlank: PropTypes.string,
  choices: PropTypes.instanceOf(Array).isRequired,
  required: PropTypes.bool,
  validation: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
};

export default FormSelect;
