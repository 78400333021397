import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useApiGet, useApiLoading } from "react-reqq";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get } from "lodash";

import FormSelect from "modules/common/forms/FormSelect";
import FormInput from "modules/common/forms/FormInput";

import * as actions from "../actions";
import * as c from "../constants";

function SetServiceInfo({ initValues, onSubmit }) {
  const history = useHistory();
  const validationSchema = yup.object({
    security_answer: yup.string().required("Security answer is required"),
  });
  const { register, handleSubmit, errors } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
  });
  const isLoading = useApiLoading(c.ACCOUNT_REGISTRATION, "post");

  const securityQuestions = useApiGet(c.LIST_SEC_QUESTIONS, []);

  useEffect(() => {
    actions.listSecurityQuestions();
  }, []);

  useEffect(() => {
    if (!get(initValues, "service_number", ""))
      history.push("/registration/step1");
  }, []);

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Security Question</h1>
      <p className="text-gray-600 text-sm mt-4">
        Fill out the basic information bellow.
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormSelect
          name="security_question"
          label="Select Security Question"
          validation={register}
          choices={get(securityQuestions, "data", []).map(
            ({ attributes: { name } }) => ({
              label: name,
              value: name,
            })
          )}
        />

        <FormInput
          placeholder="You Answer"
          name="security_answer"
          label="Security Answer"
          validation={register}
          error={errors.security_answer}
        />

        <button
          disabled={isLoading}
          className="btn primary w-full mx-auto mt-auto"
          type="submit"
        >
          Next
        </button>
      </form>
    </div>
  );
}

SetServiceInfo.propTypes = {
  initValues: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
};

export default SetServiceInfo;
