import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useApiLoading } from "react-reqq";
import { useLocation, useHistory } from "react-router-dom";
import { getToken, isSupported, getMessaging } from "firebase/messaging";

import { useAccountAttributes } from "modules/account/hooks";
import { app } from "lib/firebase";

import * as actions from "./actions";
import * as c from "./constants";

export const useOutsideClick = (componentRef, active, callback) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        callback();
        document.removeEventListener("click", handleClickOutside);
      }
    },
    [callback, componentRef]
  );

  const cleanListener = useCallback(() => {
    document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    if (active) {
      document.addEventListener("click", handleClickOutside);
    } else {
      cleanListener();
    }
  }, [active, cleanListener, handleClickOutside]);

  return [cleanListener];
};

export const useServiceTypeComponent = (configs = {}) => {
  const [{ subscription_type: service_type }] = useAccountAttributes();
  const renderNull = useCallback(() => null, []);

  switch (service_type) {
    case "PREPAID":
      return configs?.prepaid || renderNull;
    case "POSTPAID":
      return configs?.postpaid || renderNull;
    case "FIXED LINE":
      return configs?.fixline || renderNull;
    default:
      return configs?.loading || renderNull;
  }
};

export const useCountdown = (duration) => {
  const intervalRef = useRef();
  const [countdown, setCountdown] = useState("--:--");

  const startTimer = useCallback((dur) => {
    let timer = dur;
    let minutes;
    let seconds;
    intervalRef.current = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      setCountdown(`${minutes}:${seconds}`);

      if (--timer < 0) {
        setCountdown("00:00");
        clearInterval(intervalRef.current);
      }
    }, 1000);
  }, []);

  const reset = useCallback(() => {
    clearInterval(intervalRef.current);
    startTimer(duration);
  }, []);

  const countdownText = useMemo(() => countdown, [countdown]);

  useEffect(() => {
    startTimer(duration);
  }, [duration, startTimer]);

  useEffect(
    () => () => {
      clearInterval(intervalRef.current);
    },
    []
  );

  return [countdownText, reset];
};

export const useValidatePin = (onSuccess = () => {}, onError = () => {}) => {
  const validate = useCallback(actions.validatePin(onSuccess, onError), []);
  const isLoading = useApiLoading(c.VALIDATE_MPIN, "post");

  return [validate, isLoading];
};

export const useFcmToken = () => {
  const [token, setToken] = useState("");
  useEffect(() => {
    (async () => {
      const isFeatureSuported = await isSupported();
      if (!isFeatureSuported) return;
      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey:
          "BNrRbzBF8k8TpZs-CTDpZ0Ms2z-rdBxL0poexgJqNb1to3A1Ot3XjT0LjjD2vEwcGwJG0nRvIVn-GiPsnRRFYu4",
      })
        .then(setToken)
        .catch((err) => console.error("error: ", err));
    })().catch((err) => console.error("error: ", err));
  }, []);

  return token;
};

export const useRouterData = (fallbackData = {}) => {
  const isFirstMountRef = useRef(true);
  const location = useLocation();
  const history = useHistory();

  const push = (url, data = {}) => {
    history.push(url, data);
  };

  const data = useMemo(() => {
    if (isFirstMountRef.current && !!location?.state) {
      isFirstMountRef.current = false;
      return location?.state;
    }
    if (isEmpty(fallbackData) && !!location?.state) {
      return location?.state;
    }
    return fallbackData;
  }, [location, fallbackData]);

  return [data, push];
};

export const useScrollPositionListener = (
  options = {
    listenScrollPercentage: 100,
    onHitScrollPercentage: () => {},
  }
) => {
  const prevScrollPercentRef = useRef(null);

  const registerListener = useMemo(() => {
    return {
      onScroll: (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollPercentage = Math.ceil(
          (scrollTop / (scrollHeight - clientHeight)) * 100
        );
        if (prevScrollPercentRef.current >= options.listenScrollPercentage) {
          return;
        }
        if (scrollPercentage < options.listenScrollPercentage) {
          return;
        }
        prevScrollPercentRef.current = scrollPercentage;
        options.onHitScrollPercentage();
      },
    };
  }, [options]);

  const resetListener = useCallback(
    () => (prevScrollPercentRef.current = null),
    []
  );

  return { registerListener, resetListener };
};
