import { get } from "lodash";
import { useApiGet, useApiLoading } from "react-reqq";

import * as c from "./constants";

export const useAccountAttributes = () => {
  const isLoading = useApiLoading(c.ACCOUNT_INFO, "get");
  const info = useApiGet(c.ACCOUNT_INFO, {});

  const accountAttributes = {
    ...get(info, "data.attributes", {}),
    ...get(info, "included[0].attributes", {}),
    active_account_id: get(info, "included[1].id"),
  };

  return [accountAttributes, isLoading];
};

export const useAccountProfile = () => {
  const isLoading = useApiLoading(c.ACCOUNT_PROFILE, "get");
  const profile = useApiGet(c.ACCOUNT_PROFILE, {});

  return [get(profile, "data.attributes", {}), isLoading];
};
export const useAccountInvoice = () => {
  const isLoading = useApiLoading(c.ACCOUNT_INVOICE, "get");
  const invoice = useApiGet(c.ACCOUNT_INVOICE, {});

  return [get(invoice, "data.attributes", {}), isLoading];
};

export const useAccountPrepaidBalance = () => {
  const isLoading = useApiLoading(c.ACCOUNT_PREPAID_BALANCE, "get");
  const prepaid = useApiGet(c.ACCOUNT_PREPAID_BALANCE, {});

  return [get(prepaid, "data.attributes", {}), isLoading];
};

export const useAccountPostpaidBalance = () => {
  const isLoading = useApiLoading(c.ACCOUNT_POSTPAID_BALANCE, "get");
  const postpaid = useApiGet(c.ACCOUNT_POSTPAID_BALANCE, {});

  return [get(postpaid, "data.attributes", {}), isLoading];
};

export const useAccountFixedlineBalance = () => {
  const isLoading = useApiLoading(c.ACCOUNT_FIXEDLINE_BALANCE, "get");
  const fixedline = useApiGet(c.ACCOUNT_FIXEDLINE_BALANCE, {});

  return [get(fixedline, "data.attributes", {}), isLoading];
};
