import { useEffect, useCallback } from "react";
import { useApiLoading } from "react-reqq";
import Cookie from "js-cookie";
import { req, useApiGet } from "react-reqq";

import * as actions from "./actions";
import * as c from "./constants";

export const useAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, {});

  useEffect(() => {
    const token = Cookie.get("_token");
    req.set(c.AUTHENTICATION, {
      isPasswordAuthenticated: !!token,
      isPinAuthenticated: false,
    });
  }, []);

  return [auth?.isPasswordAuthenticated, auth?.isPinAuthenticated];
};

export const useLogin = () => {
  const login = useCallback(actions.login, []);
  const isLoading = useApiLoading(c.LOGIN, "post");

  return [login, isLoading];
};
