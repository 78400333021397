import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useRouterData } from "modules/common/hooks";
import IconSuccess from "assets/img/icon-success.svg";

import PaymentDetails from "../components/PaymentDetails";

function PaymentSuccess() {
  const location = useLocation();
  const history = useHistory();
  const isWebApp = location.pathname?.includes("web_app");
  // const isMobileApp = !location.pathname?.includes('web_app');

  const [paymentData] = useRouterData();
  const type = useMemo(
    () => paymentData?.order_description || paymentData?.transaction_type || "",
    [paymentData]
  );

  const textTitle = useMemo(() => {
    if (type === "PREPAID-RECHARGE") {
      return <>Recharge Successful!</>;
    }
    if (
      [
        "PREPAID-PAY-GUEST",
        "PREPAID-PAY-FRIEND",
        "POSTPAID-PAY-GUEST",
        "FIXED-LINE-PAY-GUEST",
        "FIXED-LINE-PAY-FRIEND",
        "POSTPAID-PAY-FRIEND",
      ].includes(type)
    ) {
      return <>Payment Successful!</>;
    }
    if (["POSTPAID-PAY-BILLS", "FIXED-LINE-PAY-BILLS"].includes(type)) {
      return <>Bill Payment Successful!</>;
    }
    if (type === "Cash-In")
      return (
        <>
          Wallet Top Up
          <br />
          Successful
        </>
      );
    return <>Transaction Successful!</>;
  }, [type]);
  return (
    <div className="absolute top-0 left-0 bg-gray-200 px-4 pt-10 pb-4 sm:pt-6 w-full flex flex-col items-center h-full">
      <div className="max-w-md w-full h-full flex flex-col">
        <div className="mt-10 shadow rounded-t-xl rounded-b-md relative bg-white py-8 px-4 flex-grow">
          <img
            alt="success"
            src={IconSuccess}
            className="absolute w-20 transform top-0 -translate-y-1/2 -translate-x-1/2"
            style={{
              left: "50%",
            }}
          />
          <h1 className="mt-2 mb-4 text-2xl sm:text-3xl font-semibold text-gray-800 text-center">
            {textTitle}
          </h1>
          <PaymentDetails paymentData={paymentData} />
          <div className="absolute bottom-0 transform translate-y-1/2 left-0 right-0 w-full flex px-4 space-x-3 overflow-hidden">
            {Array(14)
              .fill(null)
              .map((_, i) => (
                <span
                  key={i}
                  className="w-6 h-6 bg-gray-200 rounded-full flex-shrink-0"
                />
              ))}
          </div>
        </div>
        {isWebApp ? (
          <button
            className="btn primary w-full mt-10"
            onClick={() => history.push("/")}
          >
            Return to Home Page
          </button>
        ) : (
          <div className="h-24" />
        )}
      </div>
    </div>
  );
}

export default PaymentSuccess;
