import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormInputNumber from "modules/common/forms/FormInputNumber";
import FormSelect from "modules/common/forms/FormSelect";
import FormMobileNumber from "modules/common/forms/FormMobileNumber";
import { formatMobileNumber, placeValueRemover } from "modules/common/helper";
import { mobileNumberWithPrefixRegex } from "modules/common/regexList";

import {
  useServiceTypeList,
  useGuestAmounList,
  useSubmitIsLoading,
} from "../hooks";

function PaymentInfo({ initValues, onSubmit }) {
  const history = useHistory();
  const validationSchema = yup.object({
    service_type: yup.string().required("Select Service Type"),
    voucher_code: yup.string(),
    account_number: yup.string("Please input a valid account number"),
    // amount: yup.number("Please a valid amount"),
    mobile_number: yup
      .string()
      .matches(
        mobileNumberWithPrefixRegex,
        "Please input a valid mobile number"
      ),
  });
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
  });
  const watchedField = watch();

  const [list] = useServiceTypeList();
  const [options] = useGuestAmounList();
  const isLoading = useSubmitIsLoading();

  const serviceOptions = useMemo(
    () =>
      list.map((item) => ({
        label: item?.attributes.name,
        value: item?.attributes.code,
      })),
    [list]
  );

  const [selectedMethod, setSelectedMethod] = useState({
    payment_method: "card",
  });

  const handleOpenVoucher = () => {
    setSelectedMethod((prev) => ({
      ...prev,
      payment_method: "voucher",
    }));
  };

  useEffect(() => {
    if (watchedField.service_type) {
      setSelectedMethod((prev) => ({ ...prev, payment_method: "card" }));
    }
  }, [watchedField.service_type]);

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl font-medium">Pay as Guest</h1>
      <p className="text-gray-600 text-sm mt-4">
        {`No log in required. Simply enter the ${
          watchedField.service_type === "PREPAID"
            ? `mobile number`
            : `account number`
        } and amount to quick
        pay and be on the way`}
      </p>
      <form
        className="mt-4 flex flex-col h-full gap-2"
        onSubmit={handleSubmit((data) =>
          onSubmit({
            ...data,
            ...selectedMethod,
            mobile_number: formatMobileNumber(data?.mobile_number),
            amount: placeValueRemover(data?.amount),
          })
        )}
      >
        <FormSelect
          label="Service to Pay"
          choices={serviceOptions}
          validation={register}
          name="service_type"
          includeBlank="Select Service Type"
          error={errors.service_type}
          required
        />
        {watchedField.service_type === "PREPAID" && (
          <FormMobileNumber
            name="mobile_number"
            label="Mobile Number"
            placeholder="(+673)XXXXXXX"
            inputClassName="bg-white text-gray-900 w-full p-3 rounded-2xl mb-0 border bg-transparent text-sm mt-2"
            validation={register}
            error={errors.mobile_number}
            required
          />
        )}
        {(watchedField.service_type === "POSTPAID" ||
          watchedField.service_type === "FIXED LINE") && (
          <>
            <FormInputNumber
              placeholder="Enter account number"
              name="account_number"
              label="Account Number"
              containerClassName="mb-2"
              validation={register}
              error={errors.account_number}
              required
            />
            <FormInputNumber
              placeholder="Enter amount to pay"
              name="amount"
              label="Amount"
              containerClassName="mb-2"
              validation={register}
              leftIcon={<i className="fa fa-dollar-sign text-gray-500 mt-3" />}
              error={errors.amount}
              formatPlaceValue
              required
            />
          </>
        )}
        {watchedField.service_type === "PREPAID" && (
          <>
            {selectedMethod.payment_method === "voucher" ? (
              <FormInputNumber
                placeholder="Enter voucher pin number"
                name="voucher_code"
                label="Voucher PIN Number"
                containerClassName="mb-2"
                validation={register}
                required
              />
            ) : (
              <div className="mt-2 flex justify-center flex-wrap">
                {options.map((item) => (
                  <label
                    key={item.amount}
                    htmlFor={item.amount}
                    className={`h-10 m-1 flex items-center justify-center border ${
                      watchedField.amount === `${item.amount}`
                        ? "bg-secondary-800 text-white cursor-default"
                        : "bg-white text-gray-900 cursor-pointer"
                    } rounded-lg`}
                    style={{ flex: "1 1 30%" }}
                  >
                    <h4 className="text-base font-semibold">
                      <span
                        className={`${
                          watchedField.amount === `${item.amount}`
                            ? "text-white"
                            : "text-gray-600"
                        }`}
                      >
                        $
                      </span>
                      &nbsp;
                      {item.amount}
                    </h4>
                    <input
                      id={item.amount}
                      name="amount"
                      type="radio"
                      value={item.amount}
                      ref={register}
                      className="opacity-0"
                    />
                  </label>
                ))}
                <button
                  className="mt-2 text-sm text-left font-semibold text-primary-700"
                  onClick={handleOpenVoucher}
                >
                  I have a Voucher Card.
                </button>
              </div>
            )}
          </>
        )}
        <i className="text-gray-600 text-xs mt-auto">
          {`Please make sure to enter the valid ${
            watchedField.service_type === "PREPAID"
              ? `mobile number`
              : `account number`
          } and an exact amount
          to pay.`}
        </i>
        <button
          className="btn primary w-full mx-auto"
          type="submit"
          disabled={isLoading}
        >
          Continue
        </button>
        <button
          className="btn w-full bg-white mt-2 rounded-2xl "
          onClick={() => history.push("/")}
          disabled={isLoading}
        >
          Cancel
        </button>
      </form>
    </div>
  );
}

export default PaymentInfo;
