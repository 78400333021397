import { req } from "react-reqq";
import Cookie from "js-cookie";
import { logEvent, getAnalytics } from "firebase/analytics";
import { getToken, isSupported, getMessaging } from "firebase/messaging";

import { app } from "lib/firebase";

import * as c from "./constants";

export const login = (payload) => {
  req.post({
    key: c.LOGIN,
    url: "/api/v2/user/subscriber/login",
    payload,
    onSuccess: ({ response: { token } }) => {
      const analytics = getAnalytics(app);
      logEvent(analytics, "logout");
      Cookie.set("_token", token);
      req.set(c.AUTHENTICATION, {
        isPasswordAuthenticated: true,
        isPinAuthenticated: false,
      });
    },
  });
};

export const loginWithPin = (payload, callback = () => {}) => {
  req.post({
    key: c.LOGIN_MPIN,
    url: "/api/v2/user/subscriber/login/mpin",
    payload,
    onSuccess: callback,
  });
};

export const logout = async () => {
  let device_token = null;
  const isFeatureSuported = await isSupported();
  if (isFeatureSuported) {
    const messaging = getMessaging(app);
    device_token = await (() =>
      new Promise((res, rej) =>
        getToken(messaging, {
          vapidKey:
            "BNrRbzBF8k8TpZs-CTDpZ0Ms2z-rdBxL0poexgJqNb1to3A1Ot3XjT0LjjD2vEwcGwJG0nRvIVn-GiPsnRRFYu4",
        })
          .then(res)
          .catch(rej)
      ))().catch(console.error);
  }
  req.post({
    key: c.LOGOUT,
    url: "/api/v2/user/subscriber/logout",
    payload: {
      device: "WEB",
      device_token: device_token || "",
    },
  });
  const analytics = getAnalytics(app);
  logEvent(analytics, "logout");
  Cookie.remove("_token");
  req.reset();
  req.clearCache();
  req.set(c.AUTHENTICATION, {
    isPasswordAuthenticated: false,
    isPinAuthenticated: false,
  });
};
