import React, { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ModalMarker, AlertMarker } from "modules/common/components/Modal";
import { useAuth } from "modules/auth/hooks";
import Splash from "modules/partial/containers/Splash";
import ErrorBoundary from "modules/partial/containers/ErrorBoundary";

import withClearCache from "./ClearCache";
import Public from "./Public";
import { DrawerProvider } from "modules/common/components/Drawer";
const Private = lazy(() => import("./Private"));

const ClearCachePrivate = withClearCache(Private);

function App() {
  const [
    isPasswordAuthenticated,
    //  isPinAuthenticated
  ] = useAuth();

  return (
    <ErrorBoundary>
      <DrawerProvider>
        <ToastContainer style={{ width: 375 }} />
        <AlertMarker />
        <ModalMarker />
        {isPasswordAuthenticated ? (
          // && isPinAuthenticated
          <Suspense fallback={<Splash />}>
            <ClearCachePrivate />
          </Suspense>
        ) : (
          <Public />
        )}
      </DrawerProvider>
    </ErrorBoundary>
  );
}

export default App;
