import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import RegistrationSteps from "./RegistrationSteps";

function RegistrationContainer() {
  return (
    <Switch>
      <Route path="/registration/:step" component={RegistrationSteps} />
      <Route render={() => <Redirect to="/registration/step1" />} />
    </Switch>
  );
}

export default RegistrationContainer;
