import React, { useEffect, useCallback } from "react";
import { isEmpty } from "lodash";
import queryString from "query-string";
import { useRouteMatch, useLocation } from "react-router-dom";

import {
  payFixedlineCallback,
  paySubscriptionBillCallback,
} from "modules/postpaidFixedline-credit/actions";
import { rechargeCallback } from "modules/recharge/actions";
import { walletCashInCallback } from "modules/wallet/actions";
import IconLoader from "assets/img/icon-loader.svg";
import { useRouterData } from "modules/common/hooks";

function PaymentPending() {
  const match = useRouteMatch();
  const location = useLocation();

  const [, push] = useRouterData();

  const handleSuccess = (data) =>
    push(`${match.path}/success`, data?.attributes || {});
  const handleFailed = () => push(`${match.path}/failed`);

  const handleCallbackEnpoints = useCallback((payload) => {
    const { type = "", desc = "", order_description = "" } = payload;
    if (
      ["PREPAID-RECHARGE", "PREPAID-PAY-FRIEND", "PREPAID-PAY-GUEST"].includes(
        desc || order_description
      )
    )
      return rechargeCallback(payload, handleSuccess, handleFailed);

    if (
      [
        "POSTPAID-PAY-BILLS",
        "POSTPAID-PAY-FRIEND",
        "POSTPAID-PAY-GUEST",
      ].includes(desc || order_description)
    )
      return paySubscriptionBillCallback(payload, handleSuccess, handleFailed);

    if (
      [
        "FIXED-LINE-PAY-BILLS",
        "FIXED-LINE-PAY-FRIEND",
        "FIXED-LINE-PAY-GUEST",
      ].includes(desc || order_description)
    )
      return payFixedlineCallback(payload, handleSuccess, handleFailed);
    if (type === "Cash-In")
      return walletCashInCallback(payload, handleSuccess, handleFailed);
  }, []);

  useEffect(() => {
    const parsedQuery = queryString.parse(location.search);
    if (isEmpty(parsedQuery)) return handleFailed();
    handleCallbackEnpoints(parsedQuery);
  }, [location]);

  return (
    <>
      <img className="animate-spin" alt="processing" src={IconLoader} />
      <h1 className="text-3xl font-semibold">Processing</h1>
      <p className="font-thin">Please wait...</p>
    </>
  );
}

export default PaymentPending;
