import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const FormPinInput = ({
  name,
  mask,
  numbersOnly,
  validation,
  length,
  error,
}) => {
  const inputRef = useRef();
  const [valueHolder, setValueHolder] = useState(Array(length).fill(""));

  const focusFunc = () => {
    inputRef.current.focus();
  };

  const handleKeyPress = (e) => {
    if (numbersOnly) {
      if (e.charCode >= 48 && e.charCode <= 57) return;
      e.preventDefault();
    }
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    setValueHolder([...value, ...Array(6 - value.length).fill("")]);
  };

  useEffect(() => {
    focusFunc();
  }, []);

  return (
    <div>
      <div className="pin-input" onClick={focusFunc}>
        <input
          name={name}
          ref={(e) => {
            validation(e);
            inputRef.current = e;
          }}
          maxLength={length}
          onBlur={focusFunc}
          onKeyPress={handleKeyPress}
          onChange={handleOnChange}
        />
        {valueHolder.map((char, i) => (
          <div key={i} className="pin-cell">
            {char ? (
              !mask ? (
                <span>{char}</span>
              ) : (
                <i className="fa fa-circle text-primary-700" />
              )
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
      {error && <small className="text-xs text-red-500">{error.message}</small>}
    </div>
  );
};

FormPinInput.defaultProps = {
  mask: false,
  numbersOnly: false,
  error: "",
  length: 6,
};

FormPinInput.propTypes = {
  mask: PropTypes.bool,
  numbersOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  length: PropTypes.number,
};

export default FormPinInput;
