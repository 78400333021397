import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";

import PaymentPending from "../components/PaymentPending";
import PaymentSuccess from "../components/PaymentSuccess";
import PaymentFailed from "../components/PaymentFailed";

function PaymentRedirect() {
  const match = useRouteMatch();

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gradient-to-bl from-primary-800 to-primary-700 z-50">
      <div className="relative text-white w-full h-full flex flex-col items-center justify-center">
        <Switch>
          <Route
            exact
            path={[match.path, `${match.path}/web_app`]}
            component={PaymentPending}
          />
          <Route
            path={[`${match.path}/success`, `${match.path}/web_app/success`]}
            component={PaymentSuccess}
          />
          <Route
            path={[`${match.path}/failed`, `${match.path}/web_app/failed`]}
            component={PaymentFailed}
          />
          <Route render={() => <Redirect to={`${match.path}/failed`} />} />
        </Switch>
      </div>
    </div>
  );
}

export default PaymentRedirect;
