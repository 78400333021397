import { useCallback } from "react";
import { useApiLoading } from "react-reqq";

import * as actions from "./actions";
import * as c from "./constants";

export const useForgotMpinSendOtp = (callback = () => {}) => {
  const isLoading = useApiLoading(c.FORGOT_MPIN_REQUEST_OTP, "post");
  const requestOtp = useCallback(actions.forgotMpinSendOtp(callback), []);

  return [requestOtp, isLoading];
};

export const useForgotMpinValidateOtp = (callback = () => {}) => {
  const isLoading = useApiLoading(c.FORGOT_MPIN_VALIDATE_OTP, "post");
  const validateOtp = useCallback(actions.forgotMpinValidateOtp(callback), []);

  return [validateOtp, isLoading];
};
