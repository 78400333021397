import { req } from "react-reqq";
import { get } from "lodash";

import * as c from "./constants";

export const sendTempPasswordToEmail = (payload, callback) => {
  req.post({
    key: c.PASSWORD_RESET,
    url: "/api/v2/user/subscriber/forgot_password",
    payload,
    onSuccess: ({ response }) => {
      const email = get(response, "data.attributes.email_address", "");
      callback(email);
    },
  });
};

export const forgotMpinSendOtp = (callback = () => {}) => (payload) => {
  req.post({
    key: c.FORGOT_MPIN_REQUEST_OTP,
    url: "/api/v2/user/subscriber/forgot_mpin",
    payload,
    onSuccess: ({ response: { data } }) => {
      callback(data);
    },
  });
};

export const forgotMpinValidateOtp = (callback = () => {}) => (payload) => {
  req.post({
    key: c.FORGOT_MPIN_VALIDATE_OTP,
    url: "/api/v2/user/subscriber/forgot_mpin/confirm",
    payload,
    onSuccess: callback,
  });
};
