import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { render } from "react-dom";
import FocusTrap from "focus-trap-react";
import store from "setup/store";
import history from "setup/history";
import { useApiLoading } from "react-reqq";

export const closeModal = () => {
  setTimeout(() => {
    document.body.className = "";
    const root = document.getElementById("modal-root");
    render(<div id="modal-root" />, root);
  }, 200);
};

export const showModal = (options) => {
  const opt = {
    title: "",
    className: "modal-md",
    titleClassName: "",
    align: "justify-start",
    content: "Modal Body",
    withTitleClose: false,
    ...options,
  };
  const root = document.getElementById(opt.rootIdOverride || "modal-root");
  if (!root) {
    alert("Modal root not found!"); // eslint-disable-line
    return;
  }
  let modalRef;
  const setModalRef = (ref) => {
    modalRef = ref;
  };
  let dialogRef;
  const setDialogRef = (ref) => {
    dialogRef = ref;
  };
  let blurRef;
  const setBlurRef = (ref) => {
    blurRef = ref;
  };
  const prevClassName = document.body.className;
  const prevElem = document.activeElement;
  document.body.className = "overflow-hidden";
  const onClose = () => {
    try {
      modalRef.classList.add("exit");
      dialogRef.classList.add("exit");
      blurRef.classList.add("exit");
    } catch (err) {
      // do nothing...
    }
    setTimeout(() => {
      document.body.className = prevClassName;
      render(<div id="modal-root" />, root);
      setTimeout(() => {
        try {
          prevElem.focus();
        } catch (err) {} // eslint-disable-line
      }, 100);
    }, 200);
  };
  const renderContent = () => opt.content(onClose);
  render(
    <Provider store={store}>
      <Router history={history}>
        <FocusTrap
          focusTrapOptions={{
            allowOutsideClick: () => true,
          }}
        >
          <div
            ref={setModalRef}
            className={`modal ${opt.align}`}
            tabIndex="-1"
            role="dialog"
            aria-modal="true"
          >
            <div
              ref={setDialogRef}
              className={`container mx-auto modal-dialog ${opt.className}`}
              role="document"
            >
              {opt.title && (
                <div
                  className={`modal-title1 text-white flex justify-between ${opt.titleClassName}`}
                >
                  {opt.title}
                  {opt.withTitleClose && (
                    <button type="button" onClick={onClose} className="px-2">
                      <i className="fa fa-times" />
                    </button>
                  )}
                </div>
              )}
              {renderContent()}
            </div>
          </div>
        </FocusTrap>
        <div ref={setBlurRef} className="modal-blur" />
      </Router>
    </Provider>,
    root
  );
};

const AlertModal = ({ opt, onClose }) => {
  const isLoading = useApiLoading(opt.isLoadingKey, opt.isLoadingMethod);
  const handleYes = () => {
    opt.onYes(onClose);
  };
  const handleNo = () => {
    opt.onNo(onClose);
  };
  const renderContent = () => {
    if (typeof opt.content === "function") return opt.content(onClose);
    if (typeof opt.content === "string")
      return <div className="px-3">{opt.content}</div>;
    return "n/a";
  };
  return (
    <>
      {opt.title && (
        <div className={`modal-title1 text-white ${opt.titleClassName}`}>
          {opt.title}
        </div>
      )}
      {renderContent()}
      <div className="flex gap-2 p-3">
        <button
          className="btn outline-primary w-full rounded-lg2"
          type="button"
          onClick={handleYes}
          disabled={isLoading}
        >
          {opt.onYesLabel}
        </button>
        <button
          className="btn outline-primary w-full rounded-lg2"
          type="button"
          onClick={handleNo}
          disabled={isLoading}
        >
          {opt.onNoLabel}
        </button>
      </div>
    </>
  );
};

AlertModal.propTypes = {
  opt: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const showAlert = (options) => {
  const root = document.getElementById("alert-root");
  if (!root) {
    alert("Alert root not found!"); // eslint-disable-line
    return;
  }
  let modalRef;
  const setModalRef = (ref) => {
    modalRef = ref;
  };
  let dialogRef;
  const setDialogRef = (ref) => {
    dialogRef = ref;
  };
  let blurRef;
  const setBlurRef = (ref) => {
    blurRef = ref;
  };
  const prevClassName = document.body.className;
  const prevElem = document.activeElement;
  document.body.className = "overflow-hidden";
  const onClose = () => {
    try {
      modalRef.classList.add("exit");
      dialogRef.classList.add("exit");
      blurRef.classList.add("exit");
    } catch (err) {
      // do nothing...
    }
    setTimeout(() => {
      document.body.className = prevClassName;
      render(<div id="alert-root" />, root);
      setTimeout(() => {
        try {
          prevElem.focus();
        } catch (err) {} // eslint-disable-line
      }, 100);
    }, 200);
  };

  const opt = {
    title: "",
    isLoadingKey: null,
    isLoadingMethod: null,
    className: "modal-sm",
    titleClassName: "",
    align: "justify-center",
    content: "Confirmation Message",
    onYes: (close) => {
      close();
    },
    onYesLabel: "Yes",
    onNo: (close) => {
      close();
    },
    onNoLabel: "No",
    ...options,
  };
  render(
    <Provider store={store}>
      <Router history={history}>
        <FocusTrap
          focusTrapOptions={{
            allowOutsideClick: () => true,
          }}
        >
          <div
            ref={setModalRef}
            className={`modal ${opt.align}`}
            tabIndex="-1"
            role="dialog"
            aria-modal="true"
          >
            <div
              ref={setDialogRef}
              className={`container mx-auto modal-dialog ${opt.className}`}
              role="document"
            >
              <AlertModal opt={opt} onClose={onClose} />
            </div>
          </div>
        </FocusTrap>
        <div ref={setBlurRef} className="modal-blur" />
      </Router>
    </Provider>,
    root
  );
};

export const ModalMarker = () => {
  return <div id="modal-root" />;
};

export const AlertMarker = () => {
  return <div id="alert-root" />;
};
